import React from 'react'
import SelectedPoiListProvider from './SelectedPoiProvider';
import ExportedImageProvider from './MapProvider';
import TableProvider from './TableProvider';

const Providers = ({children}) => {
  return (
    <>
        <SelectedPoiListProvider>
          <ExportedImageProvider>
            <TableProvider>
              {children}
            </TableProvider>
          </ExportedImageProvider>
        </SelectedPoiListProvider>
    </>
  )
}

export default Providers