// react
import React, {useEffect, memo } from "react";

// openlayers
import "./BasicMap.css";
import circleIcon from "../../assets/circle.svg";
import {
  Divider,
  IconButton,
  Slider,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  AutoFixHigh,
  Delete,
  DeleteForeverOutlined,
  EditOutlined,
  InfoOutlined,
  Link,
  LinkOff,
  PolylineOutlined,
  RadioButtonCheckedOutlined,
  SquareOutlined,
  TimelineOutlined,
} from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { MuiColorInput } from 'mui-color-input';
import { colors } from "../../js/defines";

function DrawControls(props) {
  const theme = useTheme();

  var typeStrings = {
    Point: "Punkt",
    LineString: "Linie",
    Polygon: "Polygon",
  };

  const [paired, setPaired] = React.useState(true);

  const [name, setName] = React.useState("");
  const [strokeColor, setStrokeColor] = React.useState(colors[0]);
  const [strokeWidth, setStrokeWidth] = React.useState(2);
  const [fillColor, setFillColor] = React.useState(colors[0]);

  function handleName(feature, text) {
    feature.set("name", text);
    setName(text);
  }

  function halfOfHex(hexString) {
    const decimalNum = parseInt(hexString, 16);
    const halfDecimal = parseInt(decimalNum / 2);
    const hexResult = halfDecimal.toString(16);
    return hexResult.length % 2 === 1 ? `0${hexResult}` : hexResult;
  }

  function handleLineColor(feature, color) {
    let fillColorNew = color;
    if(color.length > 6) {
      let transparent = color.substring(7, 9);
      let newTransparent = halfOfHex(transparent);
      let mainColor = color.length > 0 ? color.substring(0, 7) : "#ffffff";
      fillColorNew = mainColor + newTransparent;
    }

    color = color.length > 0 ? color : "#ffffff";
    
    if (paired) {
      setFillColor(fillColorNew);
      feature.set("_fillColor", fillColorNew);
    }

    feature.set("_color", color);
    setStrokeColor(color);
  }

  function handleLineWidth(feature, width) {
    feature.set("_lineWidth", width);
    setStrokeWidth(width);
  }

  function handleFillColor(feature, color) {
    let newFillColor = color.length > 0 ? color : "#ffffff44";
    feature.set("_fillColor", newFillColor);
    setFillColor(newFillColor);
  }

  /*function handlePointDelete(feature, pos) {
    let coordinates = feature.getGeometry().getCoordinates();
    coordinates[0].splice(1, 1);
    feature.getGeometry().setCoordinates(coordinates);
  }*/

  function handlePaired() {
    setPaired(!paired);
    setTimeout(handleLineColor(feature, strokeColor), 100);
  }

  useEffect(() => {
    let feature = props.selectedFeatures != null && props.selectedFeatures.length > 0 ? props.selectedFeatures[0] : null;
    if (feature) {
      setName(feature.get("name"));
      setStrokeColor(feature.get("_color"));
      setStrokeWidth(feature.get("_lineWidth"));
      setFillColor(feature.get("_fillColor"));
    }
  }, [props.selectedFeatures]);

  let feature = props.selectedFeatures != null && props.selectedFeatures.length > 0 ? props.selectedFeatures[0] : null;
  let type = feature != null ? typeStrings[feature.getGeometry().getType()] : "UNKNOWN";

  return (
    <>
      <div
        style={{
          position: "absolute",
          display: "flex",
          backgroundColor: theme.palette.background.default,
          visibility: props.visible ? "visible" : "hidden",
          borderRadius: "100svh",
          color: "white",
          left: props.appViewSize + 16 + 72,
          top: "16px",
          zIndex: 1,
        }}
      >
        {/*<Tooltip title={!props.controlsOpen ? "Geometrie editieren / Coming soon..." : "Abbrechen"}>
          <span>
            <IconButton onClick={props.handleClick}>
              {props.controlsOpen ? <CancelOutlined /> : <EditOutlined />}
            </IconButton>
          </span>
        </Tooltip>*/}
        {props.controlsOpen ? (
          <>
            {/*<Divider orientation="vertical" variant="middle" flexItem />*/}
            <Tooltip title="Punkt"><IconButton style={{color: props.selectedTool == "Point" ? theme.palette.primary.main : null}} onClick={() => props.selectTool("Point")}><RadioButtonCheckedOutlined /></IconButton></Tooltip>
            <Tooltip title="Linie"><IconButton style={{color: props.selectedTool == "LineString" ? theme.palette.primary.main : null}} onClick={() => props.selectTool("LineString")}><TimelineOutlined /></IconButton></Tooltip>
            <Tooltip title="Box"><IconButton style={{color: props.selectedTool == "Box" ? theme.palette.primary.main : null}} onClick={() => props.selectTool("Box")}><SquareOutlined /></IconButton></Tooltip>
            <Tooltip title="Polygon"><IconButton style={{color: props.selectedTool == "Polygon" ? theme.palette.primary.main : null}} onClick={() => props.selectTool("Polygon")}><PolylineOutlined /></IconButton></Tooltip>
            <Tooltip title="Form bearbeiten"><IconButton style={{color: props.selectedTool == "EditGeometry" ? theme.palette.primary.main : null}} onClick={() => props.selectTool("EditGeometry")}><EditOutlined /></IconButton></Tooltip>
            <Tooltip title="Eckpunkte löschen"><IconButton style={{color: props.selectedTool == "DeleteVertices" ? theme.palette.primary.main : null}} onClick={() => props.selectTool("DeleteVertices") /*handlePointDelete(feature, 1)*/}><Delete /></IconButton></Tooltip>
            <Divider orientation="vertical" variant="middle" flexItem />
            <Tooltip title="Eigenschaften"><IconButton style={{color: props.selectedTool == null ? theme.palette.primary.main : null}} onClick={() => props.selectTool(null)}><InfoOutlined /></IconButton></Tooltip>
            <Tooltip title="Einrasten"><IconButton style={{color: props.snapping ? theme.palette.primary.main : null}} onClick={() => props.onSnappingChanged(!props.snapping)}><AutoFixHigh/></IconButton></Tooltip>
            {/*<Divider orientation="vertical" variant="middle" flexItem />
            <Tooltip title="Speichern"><IconButton onClick={props.saveKml}><Save /></IconButton></Tooltip>*/}
          </>
        ) : null}
      </div>

      <div
        style={{
          position: "absolute",
          display: "flex",
          padding: "10px",
          flexDirection: "column",
          width: "302px",
          backgroundColor: theme.palette.background.default,
          visibility: feature != null ? "visible" : "hidden",
          borderRadius: "20px",
          color: "white",
          left: props.appViewSize + 16 + 72,
          top: "72px",
          zIndex: 1,
        }}
      >
        <div style={{ display: "flex" }}>
          <Typography style={{ color: theme.palette.text.primary, alignSelf: "center", fontSize: 18, fontWeight: 600 }}>
            {type}
          </Typography>
          <div style={{ marginLeft: "auto" }}>
            <Tooltip title="Geometrie löschen">
              <IconButton style={{ color: theme.palette.error.main }} onClick={() => props.deleteGeometry(props.selectedFeatures[0])}>{<DeleteForeverOutlined />}</IconButton>
            </Tooltip>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <Typography style={{ color: theme.palette.text.primary }}>Name:</Typography>
          <TextField value={name} size="small" style={{marginInline: "8px"}} onChange={(e) => handleName(feature, e.target.value)}></TextField>
        </div>
        <div style={{ display: "flex", marginTop: "10px" }}>
          <Typography style={{ color: theme.palette.text.primary, alignSelf: "center" }}>{paired ? "Farbe" : "Linienfarbe"}</Typography>
          <Tooltip placement="right" title={paired ? "Entkoppeln" : "Koppeln"}><IconButton style={{ width: "32px", height: "32px" }} onClick={() => handlePaired(feature)}>{paired ? <Link /> : <LinkOff />}</IconButton></Tooltip>
        </div>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {colors.map((color) => {
            return (
              <IconButton size="small" key={color} onClick={() => handleLineColor(feature, color + "ff")} style={{ width: "32px", height: "32px", backgroundColor: color === strokeColor ? theme.palette.grey[400] : null }}>
                <img height="16px" width="16px" style={{ backgroundColor: color, mask: "url(" + circleIcon + ") no-repeat 0 0/16px 16px" }}/>
              </IconButton>
            );
          })}
          <MuiColorInput format="hex8" size="small" fullWidth style={{marginInline: "8px"}} value={strokeColor} onChange={(customColor) => handleLineColor(feature, customColor)}></MuiColorInput>
        </div>
        <Typography style={{ color: theme.palette.text.primary, marginTop: "16px" }}>Liniendicke</Typography>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          <Slider value={strokeWidth} style={{ marginInline: "8px", width: "150px" }} min={1} max={10} onChange={(e, width) => handleLineWidth(feature, width)} valueLabelDisplay="on" size="small"></Slider>
          <Typography style={{ color: theme.palette.text.primary, alignSelf: "center" }}>{strokeWidth} px</Typography>
        </div>
        {!paired ? (
          <>
            <Typography style={{ color: theme.palette.text.primary, marginTop: "10px" }}>Füllfarbe</Typography>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {colors.map((color) => {
                return (
                  <IconButton size="small" key={color} onClick={() => handleFillColor(feature, color + "7c")} style={{ width: "32px", height: "32px", backgroundColor: color === fillColor ? theme.palette.grey[400] : null }}>
                    <img height="16px" width="16px" style={{ backgroundColor: color, mask: "url(" + circleIcon + ") no-repeat 0 0/16px 16px" }}/>
                  </IconButton>
                );
              })}
              <MuiColorInput format="hex8" size="small" fullWidth style={{marginInline: "8px"}} value={fillColor} onChange={(customColor) => handleFillColor(feature, customColor)}></MuiColorInput>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}

export default memo(DrawControls);