import { Box, Button, CircularProgress, Link, TextField, Typography, styled } from "@mui/material";
import "./LoginView.css";
import "../../widgets/navBar/NavBarItem.css";
import logo from "../../assets/WIV_Logo.png";
import nice_to_see_you from "../../assets/nice_to_see_you.png";


const StyledTextField = styled(TextField)({
  "& label": {
    color: "#a4a498"
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#a4a498"
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#a4a498"
    },
    "&:hover:not(&.Mui-focused) fieldset": {
      borderColor: "#a4a498",
      borderWidth: 2
    },
  }
});

function LoginView(props) {
  var username = "";
  var password = "";

  return (
    <div className="bg">
      <div className="bg-overlay"/>
      <div
        style={{ height: "100svh", width: "100%", top: "0px", overflow: "clip" }}
      >
        <div className="login-div">
          <img
            src={logo}
            alt="WOOD.IN.VISION Logo"
            width="250px"
            style={{
              marginTop: "30px",
              userSelect: "none"
            }}
            onDragStart={(e) => e.preventDefault()}
          />
          <img
            src={nice_to_see_you}
            alt="Nice to see you ;)"
            width="250px"
            style={{
              marginTop: "30px",
              userSelect: "none",
              fliter: "blur(21px)"
            }}
            onDragStart={(e) => e.preventDefault()}
          />
          <StyledTextField
            label={"Benutzername"}
            style={{
              minWidth: "300px",
              maxWidth: "350px",
              marginLeft: "40px",
              marginRight: "40px",
              marginTop: "40px"
            }}
            error={props.usernameErr}
            sx={{ input: { color: 'white' } }}
            onChange={(e) => {username = e.target.value; props.onUserName(e.target.value);}}
          ></StyledTextField>
          <StyledTextField
            label={"Passwort"}
            type="password"
            style={{
              minWidth: "300px",
              maxWidth: "350px",
              maxWidth: "calc(100% - 120px)",
              margin: "20px",
              marginLeft: "40px",
              marginRight: "40px"
            }}
            error={props.passwordErr}
            sx={{ input: { color: 'white' } }}
            onKeyDown={(e) => e.key === "Enter" ? props.onLogin(username, password) : null}
            onChange={(e) => {password = e.target.value; props.onPassword(e.target.value);}}
          ></StyledTextField>

          <Box sx={{ m: 1, position: 'relative' }}>
            <Button
              variant="contained"
              style={{
                minWidth: "300px",
                maxWidth: "350px",
                margin: "10px",
                marginLeft: "40px",
                marginRight: "40px",
              }}
              disabled={props.loading}
              onClick={() => {
                props.onLogin(username, password);
              }}
            >
              Login
            </Button>
            {props.loading && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
          <div style={{display: "flex", alignItems: "center", padding:"20px", paddingTop: "20px"}}>
            <Link component="button" variant="body2" color={"#a4a498"} style={{margin: "auto"}} onClick={() => window.open("https://wood-in-vision.com/")}>
              Website
            </Link>
            <Link component="button" variant="body2" color={"#a4a498"} style={{margin: "auto"}} onClick={() => window.open("https://wood-in-vision.com/impressum")}>
              Impressum
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginView;
