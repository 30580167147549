import { useTheme } from '@emotion/react';
import { Add, CheckBoxOutlineBlank, ContentCopyOutlined, CopyAll, DeleteForever, Download, DownloadOutlined, EditRoad, FormatPaint, FormatPaintOutlined, Map, MapOutlined, OpenInNew, Place, PlaceOutlined, Redo, Restore, Save, SaveAltOutlined, Undo, UploadFileOutlined } from '@mui/icons-material';
import { Autocomplete, Box, Button, Checkbox, Chip, CircularProgress, darken, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, LinearProgress, Menu, MenuItem, Paper, Popper, styled, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import { DataGridPremium, GRID_CHECKBOX_SELECTION_COL_DEF, GridEditDateCell, GridEditInputCell, GridRowModes, GridToolbarContainer, GridToolbarExport, GridToolbarQuickFilter, gridClasses, useGridApiContext, useGridApiRef } from '@mui/x-data-grid-premium';
import ImageIcon from '@mui/icons-material/Image';
import { memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { callApiAsync, fillTemplate, formatText, handleFileDownload, openUrl, toBase64 } from '../../js/helper';
import ExportButton from '../export/export';
import {v4 as uuidv4} from 'uuid';
import { SelectedPoiActions, useSelectedPoiList } from '../../context/SelectedPoiProvider';
import { BACKEND_URL, muiTypes } from '../../js/defines';
import { useMap } from '../../context/MapProvider';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useTableController } from '../../context/TableProvider';

function isOverflown(element) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

const GridCellExpand = memo(function GridCellExpand(props) {
  const { width, value } = props;
  const wrapper = useRef(null);
  const cellDiv = useRef(null);
  const cellValue = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showFullCell, setShowFullCell] = useState(false);
  const [showPopper, setShowPopper] = useState(false);

  const handleMouseEnter = () => {
    const isCurrentlyOverflown = isOverflown(cellValue.current);
    setShowPopper(isCurrentlyOverflown);
    setAnchorEl(cellDiv.current);
    setShowFullCell(true);
  };

  const handleMouseLeave = () => {
    setShowFullCell(false);
  };

  useEffect(() => {
    if (!showFullCell) {
      return undefined;
    }

    function handleKeyDown(nativeEvent) {
      if (nativeEvent.key === 'Escape') {
        setShowFullCell(false);
      }
    }

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [setShowFullCell, showFullCell]);

  return (
    <Box
      ref={wrapper}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      sx={{
        alignItems: 'center',
        lineHeight: '24px',
        width: '100%',
        height: '100%',
        position: 'relative',
        display: 'flex',
        zIndex: 10,
      }}
    >
      <Box
        ref={cellDiv}
        sx={{
          height: '100%',
          width,
          display: 'block',
          position: 'absolute',
          top: 0,
          zIndex: 10,
        }}
      />
      <Box
        ref={cellValue}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {value}
      </Box>
      {showPopper && (
        <Popper
          open={showFullCell && anchorEl !== null}
          anchorEl={anchorEl}
          style={{ width, marginLeft: -17, zIndex: 10 }}
        >
          <Paper
            elevation={1}
            style={{ minHeight: wrapper.current.offsetHeight - 3 }}
          >
            <Typography noWrap={false} variant="body2" style={{ padding: 8, maxWidth: "300px" }}>
              {value}
            </Typography>
          </Paper>
        </Popper>
      )}
    </Box>
  );
});

function CustomBooleanEditComponent(props) {
  const { id, value, field, hasFocus, column } = props;
  const apiRef = useGridApiContext();
  const ref = useRef();

  useLayoutEffect(() => {
    if (hasFocus && ref) {
      ref.current.focus();
    }
  }, [hasFocus, ref]);

  return <Checkbox ref={ref} size='small' checked={value} onChange={(e, newValue) => {console.log(newValue); apiRef.current.setEditCellValue({ id, field, value: newValue});}}/>
}

function CustomVectorOverlayEditComponent(props) {
  const { id, value, field, hasFocus, column, accept, username, password } = props;
  const apiRef = useGridApiContext();

  return <>
    {value ? <IconButton style={{verticalAlign: "sub", alignContent: "center"}} onClick={(e) => handleFileDownload(value, username, password, e)}><Download sx={{fontSize: "18px"}}/></IconButton> : null}
    <IconButton onClick={() => {
      const elem = document.createElement("input");
      elem.type = "file";
      elem.accept = accept ? accept : null;
      elem.addEventListener("change", async () => {
        if (elem.files.length == 1) {
          let base64 = await toBase64(elem.files[0]);
          let newValue = {
            "l": elem.files[0].name,
            "base64": base64.split(",")[1],
            "mimeType": elem.files[0].type,
            "type": "vectorOverlay",
            "objectType": "BlobInfo"
          }
          apiRef.current.setEditCellValue({ id, field, value: newValue });
        }
      });
      elem.click();
    }}><UploadFileOutlined sx={{fontSize: "18px"}}/></IconButton>
    {value ? value.l : ""}
  </>
}

function CustomFileEditComponent(props) {
  const { id, value, field, hasFocus, column, accept, username, password } = props;
  const apiRef = useGridApiContext();

  return <>
    {value ? <IconButton style={{verticalAlign: "sub", alignContent: "center"}} onClick={(e) => handleFileDownload(value, username, password, e)}><Download sx={{fontSize: "18px"}}/></IconButton> : null}
    <IconButton onClick={() => {
      const elem = document.createElement("input");
      elem.type = "file";
      elem.accept = accept ? accept : null;
      elem.addEventListener("change", async () => {
        if (elem.files.length == 1) {
          let base64 = await toBase64(elem.files[0]);
          let newValue = {
            "l": elem.files[0].name,
            "base64": base64.split(",")[1],
            "mimeType": elem.files[0].type,
            "type": "document",
            "objectType": "BlobInfo"
          }
          apiRef.current.setEditCellValue({ id, field, value: newValue });
        }
      });
      elem.click();
    }}><UploadFileOutlined sx={{fontSize: "18px"}}/></IconButton>
    {value ? value.l : ""}
  </>
}

function CustomImageEditComponent(props) {
  const { id, value, field, hasFocus, column, accept, username, password } = props;
  const apiRef = useGridApiContext();

  return <>
    {value ? <IconButton style={{verticalAlign: "sub", alignContent: "center"}} onClick={(e) => handleFileDownload(value, username, password, e)}><ImageIcon sx={{fontSize: "18px"}}/></IconButton> : null}
    <IconButton onClick={() => {
      const elem = document.createElement("input");
      elem.type = "file";
      elem.accept = accept ? accept : null;
      elem.addEventListener("change", async () => {
        if (elem.files.length == 1) {
          console.log("File selected: ", elem.files[0]);
          console.log(id, field, elem.files[0]);
          let base64 = await toBase64(elem.files[0]);
          let newValue = {
            "l": elem.files[0].name,
            "base64": base64.split(",")[1],
            "mimeType": elem.files[0].type,
            "type": "document",
            "objectType": "BlobInfo"
          }
          apiRef.current.setEditCellValue({ id, field, value: newValue });
        }
      });
      elem.click();
    }}><UploadFileOutlined sx={{fontSize: "18px"}}/></IconButton>
    {value ? value.l : ""}
  </>
}

function CustomSingleSelectEditComponent(props) {
  const { id, value, field, hasFocus, column } = props;
  const [open, setOpen] = useState(true);
  const apiRef = useGridApiContext();
  const ref = useRef();

  useLayoutEffect(() => {
    if (hasFocus && ref) {
      ref.current.focus();
    }
  }, [hasFocus, ref]);

  let selected = column.list ? column.list.find((entry) => entry.key == value) : null;

  console.log(column, column.list, value)

  return <Autocomplete
    ref={ref}
    fullWidth
    open={open}
    onOpen={(e) => setOpen(!open)}
    style={{paddingInline: "10px"}}
    size='small'
    options={column.list ? column.list : []}
    value={selected}
    getOptionLabel={(option) => option.value}
    onChange={(e, value) => {console.log(value); apiRef.current.setEditCellValue({ id, field, value: value ? value.key : "" }); setOpen(false);}}
    renderInput={(params) => <TextField fullWidth variant='standard' size='small' {...params} InputProps={{...params.InputProps, disableUnderline: true, style: {...params.InputProps.style, fontSize: "0.875rem", padding: "3px 56px 1px 1px"}}} style={{marginTop: "1px"}}/>}
  />
}

function CustomMultiSelectEditComponent(props) {
  const { id, value, field, hasFocus, colDef, column } = props;
  const apiRef = useGridApiContext();
  const [valueState, setValueState] = useState(value);
  const [anchorEl, setAnchorEl] = useState();
  const [inputRef, setInputRef] = useState(null);
  const ref = useRef();

  useLayoutEffect(() => {
    if (hasFocus && inputRef) {
      inputRef.focus();
    }
  }, [hasFocus, ref, inputRef]);

  const handleRef = useCallback((el) => {
    setAnchorEl(el);
  }, []);

  const handleChange = useCallback(
    (event) => {
      const newValue = event.target.value;
      setValueState(newValue);
      apiRef.current.setEditCellValue(
        { id, field, value: newValue, debounceMs: 200 },
        event,
      );
    },
    [apiRef, field, id],
  );

  let list = props.freeSolo ? ((value != null && value != "") ? value.split(";").map((value) => {return({"key": value, "value": value})}) : []) : (column.list ? column.list : []);
  let selected = list ? list.filter((entry) => value ? value.split(";").includes(entry.key) : false) : null;

  return(
    <div style={{ position: 'relative', alignSelf: 'flex-start' }}>
      <div
        ref={handleRef}
        style={{
          height: 1,
          width: colDef.computedWidth,
          display: 'block',
          position: 'absolute',
          top: 0,
        }}
      />
      {anchorEl && (
        <Popper open anchorEl={anchorEl} placement="bottom" style={{zIndex: 3}}>
          <Paper elevation={1} sx={{width: colDef.computedWidth, minHeight: "36px"}}>
            <Autocomplete
              multiple
              freeSolo={true}
              ref={ref}
              disableCloseOnSelect
              openOnFocus={true}
              style={{paddingInline: "10px"}}
              size='small'
              options={list}
              value={selected}
              getOptionLabel={(option) => option.value}
              ChipProps={{color: props.freeSolo ? "primary" : "default"}}
              onChange={(e, values) => {
                let newValue = values ? values.map((value) => value.key ? value.key : value).join(";") : "";
                apiRef.current.setEditCellValue({ id, field, value: newValue });
              }}
              renderInput={(params) => <TextField fullWidth variant='standard' size='small' {...params} InputProps={{...params.InputProps, disableUnderline: true}} style={{marginTop: "3px"}}/>}
              renderOption={(props, option, { selected }) => {
                const { key, ...optionProps } = props;
                return (
                  <li key={key} {...optionProps} style={{paddingBlock: "0px", paddingInline: "10px"}}>
                    <Checkbox
                      size='small'
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.value}
                  </li>
                );
              }}
            />
          </Paper>
        </Popper>
      )}
    </div>
  );
}


function CustomLocationEditComponent(props) {
  const { id, value, field, hasFocus, column } = props;
  const [open, setOpen] = useState(true);
  const apiRef = useGridApiContext();
  const ref = useRef();

  useLayoutEffect(() => {
    if (hasFocus && ref) {
      ref.current.focus();
    }
  }, [hasFocus, ref]);

  let selected = column.list ? column.list.find((entry) => entry.key == value) : null;

  console.log(column, column.list, value)

  return <Autocomplete
    ref={ref}
    fullWidth
    open={open}
    onOpen={(e) => setOpen(!open)}
    style={{paddingInline: "10px"}}
    size='small'
    options={column.list ? column.list : []}
    value={selected}
    getOptionLabel={(option) => option.value}
    onChange={(e, value) => {console.log(value); apiRef.current.setEditCellValue({ id, field, value: value ? value.key : "" }); setOpen(false);}}
    renderInput={(params) => <TextField fullWidth variant='standard' size='small' {...params} InputProps={{...params.InputProps, disableUnderline: true, style: {...params.InputProps.style, fontSize: "0.875rem", padding: "3px 56px 1px 1px"}}} style={{marginTop: "1px"}}/>}
  />
}


function CustomToolbar(props) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    if(props.selectedPoiConfig) {
      setAnchorEl(event.currentTarget);
    }
  };

  let showIconsActive = useMemo(() => {return(props.poiUniqueId ? props.mapConfig && props.mapConfig[props.poiUniqueId] ? props.mapConfig[props.poiUniqueId].showIcons : false : false)}, [props.mapConfig, props.poiUniqueId]);
  let showIconsTextActive = useMemo(() => {return(props.poiUniqueId ? props.mapConfig && props.mapConfig[props.poiUniqueId] ? props.mapConfig[props.poiUniqueId].showIconsText : false : false)}, [props.mapConfig, props.poiUniqueId]);
  let showLayerActive = useMemo(() => {return(props.poiUniqueId ? props.mapConfig && props.mapConfig[props.poiUniqueId] ? props.mapConfig[props.poiUniqueId].showLayer : false : false)}, [props.mapConfig, props.poiUniqueId]);
  let hasLayer = useMemo(() => {return(props.selectedPoiConfig ? props.selectedPoiConfig.config.rows.findIndex((row) => row.type == "vectorOverlay" ) >= 0 : false)}, [props.mapConfig, props.selectedPoiConfig]);
  let parentList = useMemo(() => {
    let parentConfig = props.selectedPoiConfig ? props.configs.find((config) => (config.config.namespace + "/" + config.config.containerId) == props.selectedPoiConfig.parentUniqueId) : null;
    let templateText;
    if(parentConfig) {
      let templateTextObj = parentConfig.config["ui.web"].find((item) => item.key == "instanceMenuLabelTemplate");
      if(templateTextObj) {
        templateText = templateTextObj.value;
      }
    }
    return(props.selectedPoiConfig && props.poiList[props.selectedPoiConfig.parentUniqueId] ? props.poiList[props.selectedPoiConfig.parentUniqueId].map((item) => ({"key": item.std.guid, "value": templateText ? fillTemplate(parentConfig.config, templateText, item) : item.std.guid})) : [])
  }, [props.poiList, props.selectedPoiConfig]);
  let selectedValues = useMemo(() => {return(props.parentGuids ? parentList.filter((entry) => props.parentGuids.includes(entry.key))[0] : null)}, [props.parentGuids, parentList]);

  let hasIconsStyle = false;
  let hasLayerStyle = false;
  for(let style of props.gisStyles) {
    if(style.metaList_poiUniqueId != "" && !style.metaList_poiUniqueId.includes("//x_map")) {
      hasIconsStyle = true;
    }
    if(style.metaList_poiUniqueId.includes("//x_map")) {
      hasLayerStyle = true;
    }
  }

  useEffect(() => {
    /*if(quickFilterRef.current) {
      let selectedFilterText = props.selectedPoiConfig ? filterTexts[props.selectedPoiConfig.config.namespace + "/" + props.selectedPoiConfig.config.containerId] ? filterTexts[props.selectedPoiConfig.config.namespace + "/" + props.selectedPoiConfig.config.containerId] : [] : [];
      console.log("SET FILTER", selectedFilterText)
      quickFilterRef.current.setQuickFilterValues(selectedFilterText ? selectedFilterText : []);
    }*/
  }, []);

  return (
    <GridToolbarContainer style={{backgroundColor: theme.palette.background.default, padding: "0px", gap: "6px", marginInline: "2px"}}>

      {props.selectedPoiConfig != null && props.selectedPoiConfig.parentUniqueId ?
        <Autocomplete
        size='small'
        style={{width: "200px"}}
        value={selectedValues ? selectedValues : null}
        options={parentList}
        getOptionLabel={(option) => option.value}
        getOptionKey={(option) => option.key}
        onChange={(e, value) => {
          props.onRowSelectionModelChange(props.selectedPoiConfig.parentUniqueId, value ? [value.key] : [], true, props.selectedPoiConfig);
        }}
        renderInput={(params) => <TextField variant='outlined' placeholder='Haupteintrag' {...params} InputProps={{...params.InputProps, style:{ ...params.InputProps.style, height: "36px" }}}/>}
      /> : null}
      
      {/*Filter*/}
      <GridToolbarQuickFilter variant='outlined' size='small' style={{marginBlock: "2px", padding: "0px"}} inputProps={{style: {height: "19px", margin: "0px"}}} />

      {/*Export*/}
      <Divider orientation="vertical" variant='middle' flexItem/>
      <ExportButton
        poiList={props.poiList}
        configs={props.configs}
        selectedPoiConfig={props.selectedPoiConfig}
        gisStyles={props.gisStyles}
        dataGridRef={props.dataGridRef}
        username={props.username}
        disabled={props.selectedPoiConfig == null}
        companyLogo={props.companyLogo}
        userSettings={props.userSettings}
      />
      
      {/*Table Control*/}
      <Divider orientation="vertical" variant='middle' flexItem/>
      <Tooltip placement='top' title={"Änderungen verwerfen"}>
        <span>
          <IconButton size='small' disabled={!(props.selectedPoiConfig != null && props.hasUnsavedRows)} onClick={() => props.discardChanges()}><Restore/></IconButton>
        </span>
      </Tooltip>
      <Tooltip placement='top' title={"Rückgänging / Coming soon..."}>
        <span>
          <IconButton size='small' onClick={() => props.handleUndo()} disabled/*disabled={props.unsavedChangesRef.current.undoList.length === 0 || props.unsavedChangesRef.current.currentState === 0}*/><Undo/></IconButton>
        </span>
      </Tooltip>
      <Tooltip placement='top' title={"Wiederherstellen / Coming soon..."}>
        <span>
          <IconButton size='small' onClick={() => props.handleRedo()} disabled/*disabled={props.unsavedChangesRef.current.undoList.length === 0 || props.unsavedChangesRef.current.currentState === props.unsavedChangesRef.current.undoList.length - 1}*/><Redo/></IconButton>
        </span>
      </Tooltip>
      <Tooltip placement='top' title={"Geometrie editieren"}>
        <span>
          <IconButton size='small' disabled={!(props.selectedPoisByUniqueId != null && props.selectedPoisByUniqueId.length == 1 && showLayerActive)} style={{color: props.editGeometry == true ? theme.palette.primary.main : null}} onClick={() => props.onEditGeometry()}><EditRoad/></IconButton>
        </span>
      </Tooltip>
      <Tooltip placement='top' title={"Eintrag löschen"}>
        <span>
          <IconButton size='small' disabled={!(props.selectedPoiConfig != null && props.selectedTableRows.length > 0)} onClick={() => props.deleteEntriesDialog()}><DeleteForever/></IconButton>
        </span>
      </Tooltip>
      <Tooltip placement='top' title={"Änderungen speichern"}>
        <span>
          <IconButton size='small' disabled={!(props.selectedPoiConfig != null && (props.hasUnsavedRows && !props.isSaving))} onClick={() => props.saveChanges()}>{!props.isSaving ? <Save/> : <CircularProgress size={"24px"}/>}</IconButton>
        </span>
      </Tooltip>
      <Divider orientation="vertical" variant='middle' flexItem/>
      <Tooltip placement='top' title={"Eintrag erstellen"}>
        <span>
          <IconButton size='small' disabled={!((props.selectedPoiConfig != null && !props.selectedPoiConfig.parentUniqueId) || (props.selectedPoiConfig != null && props.selectedPoiConfig.parentUniqueId) && props.parentGuids != null && props.parentGuids.length == 1)} onClick={() => props.createEntry()}><Add/></IconButton>
        </span>
      </Tooltip>

      <Box sx={{ flexGrow: 1 }}/>
      {/*<Tooltip placement='top' title={showIconsActive ? "Markierung ausblenden" : "Markierung einblenden"}>*/}
          <IconButton
            size='small'
            disabled={props.selectedPoiConfig == null}
            style={{color: showIconsActive ? theme.palette.primary.main : null}}
            onClick={() => !props.disableButtons ? props.onMapConfigChanged(props.poiUniqueId, "showIcons", !showIconsActive) : null}
          >
            {showIconsActive ? <Place/> : <PlaceOutlined/>}
          </IconButton>
      {/*</Tooltip>*/}
      {/*<Tooltip placement='top' title={showLayerActive ? "Geometrien ausblenden" : "Geometrien einblenden"}>*/}
          <IconButton
            size='small'
            disabled={props.selectedPoiConfig == null || !hasLayer}
            style={{color: showLayerActive ? theme.palette.primary.main : null}}
            onClick={() => !props.disableButtons ? props.onMapConfigChanged(props.poiUniqueId, "showLayer", !showLayerActive) : null}
          >
            {showLayerActive ? <Map/> : <MapOutlined/>}
          </IconButton>
      {/*</Tooltip>*/}
      {/*<Tooltip placement='top' title="Style wählen">*/}
          <IconButton
            size='small'
            onClick={handleClick}
            disabled={props.selectedPoiConfig == null && props.gisStyles.length === 0}
            style={{color: props.selectedStyle != "" || props.selectedMapStyle != "" ? theme.palette.primary.main : null}}
          >
            {props.selectedStyle != "" || props.selectedMapStyle != "" ? <FormatPaint/> : <FormatPaintOutlined/>}
          </IconButton>
      {/*</Tooltip>*/}
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={open}
        onClose={() => setAnchorEl(null)}
      >
        <Divider>Standard</Divider>
        <MenuItem
          key={"noLabel"}
          selected={!showIconsTextActive}
          onClick={() => props.onMapConfigChanged(props.poiUniqueId, "showIconsText", false)}
          style={{gap: "4px"}}
        >
          <Place/>
          Keine Beschriftung
        </MenuItem>
        <MenuItem
          key={"defaultLabel"}
          selected={showIconsTextActive}
          onClick={() => props.onMapConfigChanged(props.poiUniqueId, "showIconsText", true)}
          style={{gap: "4px"}}
        >
          <Place/>
          Standard Beschriftung
        </MenuItem>
        {hasIconsStyle ? <Divider>Markierung</Divider> : null}
        {props.gisStyles != [] ? props.gisStyles.map((style) => (
          !style.metaList_poiUniqueId.includes("//x_map") ?
            <MenuItem
              key={style.poi.std.guid}
              selected={style.poi.std.guid === props.selectedStyle || style.poi.std.guid === props.selectedMapStyle}
              onClick={() => props.onGisStyleChanged(style.metaList_poiUniqueId, style.poi.std.guid)}
              style={{gap: "4px"}}
            >
              <Place/>
              {style.poi.poi["styleName"]}
            </MenuItem> : null
        )) : null}

        {hasLayerStyle ? <Divider>Geometrien</Divider> : null}
        {props.gisStyles != [] ? props.gisStyles.map((style) => (
          style.metaList_poiUniqueId.includes("//x_map") ?
            <MenuItem
              key={style.poi.std.guid}
              selected={style.poi.std.guid === props.selectedStyle || style.poi.std.guid === props.selectedMapStyle}
              onClick={() => props.onGisStyleChanged(style.metaList_poiUniqueId, style.poi.std.guid)}
              style={{gap: "4px"}}
            >
              <Map/>
              {style.poi.poi["styleName"]}
            </MenuItem> : null
        )) : null
        }
      </Menu>

      <Dialog
        open={props.itemsToDelete.length > 0}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.itemsToDelete.length > 1 ? "Einträge" : "Eintrag"} löschen</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.itemsToDelete.length > 1 ? "Sind Sie sicher, dass Sie die ausgewählten " + props.itemsToDelete.length + " Einträge löschen möchten?" : "Sind Sie sicher, dass Sie den ausgewählten Eintrag löschen möchten?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: "grey" }} onClick={() => { props.handleItemsToDelete([]) }}>
            Abbrechen
          </Button>
          <Button
            style={{ backgroundColor: theme.palette.error.main }}
            variant="contained"
            onClick={() => { props.deleteEntries(props.itemsToDelete) }}
            autoFocus
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </GridToolbarContainer>
  );
}

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .no-rows-primary': {
    fill: '#3D4751',
    ...theme.applyStyles('light', {
      fill: '#AEB8C2',
    }),
  },
  '& .no-rows-secondary': {
    fill: '#1D2126',
    ...theme.applyStyles('light', {
      fill: '#E8EAED',
    }),
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width={96}
        viewBox="0 0 452 257"
        aria-hidden
        focusable="false"
      >
        <path
          className="no-rows-primary"
          d="M348 69c-46.392 0-84 37.608-84 84s37.608 84 84 84 84-37.608 84-84-37.608-84-84-84Zm-104 84c0-57.438 46.562-104 104-104s104 46.562 104 104-46.562 104-104 104-104-46.562-104-104Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 113.929c3.905-3.905 10.237-3.905 14.142 0l63.64 63.64c3.905 3.905 3.905 10.236 0 14.142-3.906 3.905-10.237 3.905-14.142 0l-63.64-63.64c-3.905-3.905-3.905-10.237 0-14.142Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 191.711c-3.905-3.906-3.905-10.237 0-14.142l63.64-63.64c3.905-3.905 10.236-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142l-63.64 63.64c-3.905 3.905-10.237 3.905-14.142 0Z"
        />
        <path
          className="no-rows-secondary"
          d="M0 10C0 4.477 4.477 0 10 0h380c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 20 0 15.523 0 10ZM0 59c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 69 0 64.523 0 59ZM0 106c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 153c0-5.523 4.477-10 10-10h195.5c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 200c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 247c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10Z"
        />
      </svg>
      <Box sx={{ mt: 2 }}>Keine Einträge vorhanden</Box>
    </StyledGridOverlay>
  );
}


export default function TabViewTable(props) {
  const theme = useTheme();

  const { configs, poiList, gisStyles, selectedPoiConfig, mapConfig, selectedRows, selectedTableRows, selectedStyle, onFilterModelChange, onRowSelectionModelChange, selectedMapStyle, onMapConfigChanged, onGisStyleChanged, companyLogo, editGeometry, selectedPoisByUniqueId, disableButtons, userSettings, username } = props;
  const { mapData } = useMap();

  const dataGridRef = useGridApiRef(null);
  const [filterTexts, setFilterTexts] = useState({});
  const [rowModesModel, setRowModesModel] = useState({});
  
  const [hasUnsavedRows, setHasUnsavedRows] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [itemsToDelete, setItemsToDelete] = useState([]);

  const unsavedChangesRef = useRef({
    unsavedRows: {},
    rowsBeforeChange: {},
    undoList: [],
    currentState: 0
  });

  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedColumn, setSelectecColumn] = useState(null);
  const [contextMenu, setContextMenu] = useState(null);
  const [showDetails, setShowDetails] = useState(false);
  const [showStatistic, setShowStatistic] = useState(false);

  const handleContextMenu = (event) => {
    event.preventDefault();
    if(dataGridRef.current.getColumn) {
      let columnKey = event.currentTarget.getAttribute("data-field");
      let column = dataGridRef.current.getColumn(columnKey);
      setSelectecColumn(column);
    }
    setSelectedRow(event.currentTarget);
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };


  const createEntry = useCallback(() => {
    setHasUnsavedRows(true);

    let guid = uuidv4();
    let pos = mapData.getMapPosFunction();

    let newEntry = {
      parent: selectedPoiConfig != null && selectedPoiConfig.parentUniqueId && parentGuids ? {
        std: {
          poiUniqueId: selectedPoiConfig.parentUniqueId,
          guid: parentGuids[0]
        }
      } : null,
      poi: {
      },
      std: {
        ts: new Date().toISOString(),
        loc: {
          lat: pos ? pos.pos[1] : 0,
          lon: pos ? pos.pos[0] : 0
        },
        poiUniqueId: selectedPoiConfig.config.namespace + "/" + selectedPoiConfig.config.containerId,
        guid: guid
      },
      id: guid,
      isNew: true
    }

    dataGridRef.current.updateRows([newEntry]);

    const rowId = newEntry.id;
    unsavedChangesRef.current.unsavedRows[rowId] = newEntry;
    if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
      unsavedChangesRef.current.rowsBeforeChange[rowId] = newEntry;
    }
    setHasUnsavedRows(true);

  }, [dataGridRef, selectedPoiConfig, mapData]);

  const { setProcessRowUpdate } = useTableController();
  useEffect(() => {
    setProcessRowUpdate(processRowUpdate);
  }, [props.poiList]);

  const processRowUpdate = useCallback((newRow, oldRow) => {
    const rowId = newRow.id;

    //Single Undo
    unsavedChangesRef.current.unsavedRows[rowId] = newRow;
    if (!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
      unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow;
    }

    //Update Table Row
    let unsavedPois = Object.entries(unsavedChangesRef.current.unsavedRows).map((entry) => entry[1]);
    let poiUniqueId = unsavedPois[0].std.poiUniqueId;
    props.onTableRowsChanged(poiUniqueId, unsavedPois);

    //Multi undo
    /*if(!unsavedChangesRef.current.unsavedRows[rowId]) {
      unsavedChangesRef.current.unsavedRows[rowId] = [oldRow];
      unsavedChangesRef.current.undoList.push({guid: rowId, state: unsavedChangesRef.current.unsavedRows[rowId].length-1});
      unsavedChangesRef.current.currentState = unsavedChangesRef.current.undoList.length - 1;
    }
    unsavedChangesRef.current.unsavedRows[rowId].push(newRow);

    if(!unsavedChangesRef.current.rowsBeforeChange[rowId]) {
      unsavedChangesRef.current.rowsBeforeChange[rowId] = oldRow;
    }

    unsavedChangesRef.current.undoList.push({guid: rowId, state: unsavedChangesRef.current.unsavedRows[rowId].length-1});
    unsavedChangesRef.current.currentState = unsavedChangesRef.current.undoList.length - 1;

    console.log(unsavedChangesRef.current)*/

    setHasUnsavedRows(true);
    return newRow;
  }, [props.poiList]);

  const [geometrySaveDlg, setGeometrySaveDlg] = useState(false);

  const onEditGeometry = useCallback(() => {
    if(props.editGeometry) {
      setGeometrySaveDlg(true);
    } else {
      props.onEditGeometry(!props.editGeometry);
    }
  }, [props.editGeometry]);

  const handleNo = () => {
    setGeometrySaveDlg(false);
    props.onEditGeometry(false);
  };

  const handleYes = async () => {
    let selectedRow = props.selectedTableRows[0];
    if(selectedRow) {
      let poi = unsavedChangesRef.current.unsavedRows[selectedRow];
      if(poi == null) {
        poi = rows.find((row) => row.std.guid == selectedRow);
      }
      let newPoi = structuredClone(poi);
      let kmlData = mapData.getGeometryAsKmlFunction();
      mapData.changeIgnoreZoomOnGeometryFunction(true);
      newPoi.poi[kmlData.vectorOverlayKey] = {
        "l": new Date().toISOString() + ".kml",
        "base64": btoa(kmlData.data),
        "mimeType": "application/vnd.google-earth.kml+xml",
        "type": "vectorOverlay",
        "objectType": "BlobInfo"
      }
      processRowUpdate(newPoi, poi);
    }
    setGeometrySaveDlg(false);
    props.onEditGeometry(false);
  };

  const renderConfirmDialog = () => {
    return (
      <Dialog
        open={geometrySaveDlg}
      >
        <DialogTitle>{"Änderungen übernehmen?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sind Sie sicher, das Sie die Änderungen übernehmen möchten?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNo}>
            Verwerfen
          </Button>
          <Button variant='contained' onClick={handleYes}>Übernehmen</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleUndo = useCallback(() => {
    unsavedChangesRef.current.currentState -= 1;
    let undoItem = unsavedChangesRef.current.undoList[unsavedChangesRef.current.currentState];
    let newRow = unsavedChangesRef.current.unsavedRows[undoItem.guid][undoItem.state];
    dataGridRef.current.updateRows([newRow]);
    console.log(unsavedChangesRef.current)
    return newRow;
  }, []);

  const handleRedo = useCallback(() => {
    unsavedChangesRef.current.currentState += 1;
    let undoItem = unsavedChangesRef.current.undoList[unsavedChangesRef.current.currentState];
    let newRow = unsavedChangesRef.current.unsavedRows[undoItem.guid][undoItem.state];
    dataGridRef.current.updateRows([newRow]);
    console.log(unsavedChangesRef.current)
    return newRow;
  }, []);

  const discardChanges = useCallback(() => {
    setHasUnsavedRows(false);
    Object.values(unsavedChangesRef.current.rowsBeforeChange).forEach((row) => {
      dataGridRef.current.updateRows([row]);
    });
    
    let rowsBeforeChange = Object.entries(unsavedChangesRef.current.rowsBeforeChange).map((entry) => entry[1]);
    let poiUniqueId = rowsBeforeChange[0].std.poiUniqueId;
    props.onTableRowsChanged(poiUniqueId, rowsBeforeChange);

    unsavedChangesRef.current = {
      unsavedRows: {},
      rowsBeforeChange: {},
      undoList: [],
      currentState: 0
    };
  }, [dataGridRef, unsavedChangesRef, props.poiList, props.selectedPoiConfig]);

  const saveChanges = useCallback(async () => {
    let auth = btoa(props.username + ":" + props.password);
    
    const header = new Headers();
    header.append("Content-Type", "application/json");

    try {
      setIsSaving(true);
      /*unsavedChangesRef.current.currentState -= 1;
      let undoItem = unsavedChangesRef.current.undoList[unsavedChangesRef.current.currentState];
      let newRow = unsavedChangesRef.current.unsavedRows[undoItem.guid][undoItem.state];*/

      let unsavedPois = Object.entries(unsavedChangesRef.current.unsavedRows).map((entry) => entry[1]);
      let changedPois = unsavedPois.filter((item) => !item.isNew).map((poi) => {
        delete poi.id;
        if(poi.parent == null) {
          delete poi.parent;
        }
        return poi;
      });
      let newPois = unsavedPois.filter((item) => item.isNew).map((poi) => {
        delete poi.id;
        delete poi.isNew;
        if(poi.parent == null) {
          delete poi.parent;
        }
        return poi;
      });
      let saveSuccessful = true;

      if(changedPois.length > 0) {
        let poiUniqueId = changedPois[0].std.poiUniqueId;
        let jsonData = JSON.stringify({
          "url": "https://portal.wood-in-vision.com/api/v1/poi?poiUniqueId=" + poiUniqueId.replace("/", "%2C"),
          "systemAuthToken": auth,
          "data": JSON.stringify(changedPois)
        });
        let requestOptions = {
          headers: header,
          method: "PUT",
          body: jsonData,
          redirect: "follow"
        };
        await new Promise((resolve) => {
          callApiAsync(BACKEND_URL + "/api/Fetcher/PutByBridge", requestOptions, (response) => {
            if(response != null) {
              props.onTableRowsChanged(poiUniqueId, changedPois);
            } else {
              saveSuccessful = false;
            }
            resolve()
          });
        });
      }

      if(newPois.length > 0) {
        let poiUniqueId = newPois[0].std.poiUniqueId;
        let jsonData = JSON.stringify({
          "url": "https://portal.wood-in-vision.com/api/v1/poi?poiUniqueId=" + poiUniqueId.replace("/", "%2C"),
          "systemAuthToken": auth,
          "data": JSON.stringify(newPois)
        });

        let requestOptions = {
          headers: header,
          method: "POST",
          body: jsonData,
          redirect: "follow"
        };
        await new Promise((resolve) => {
          callApiAsync(BACKEND_URL + "/api/Fetcher/PostByBridge", requestOptions, (response) => {
            if(response != null) {
              props.onTableRowsChanged(poiUniqueId, newPois);
            } else {
              saveSuccessful = false;
            }
            resolve()
          });
        });
      }

      setIsSaving(false);
      if(saveSuccessful) {
        setHasUnsavedRows(false);
        unsavedChangesRef.current = {
          unsavedRows: {},
          rowsBeforeChange: {},
          undoList: [],
          currentState: 0
        };
      }
    } catch (error) {
      setIsSaving(false);
    }
  }, [dataGridRef, unsavedChangesRef, props.poiList, props.selectedPoiConfig]);

  const deleteEntriesDialog = useCallback(() => {
    setItemsToDelete(props.selectedTableRows);
  }, [dataGridRef, props.selectedTableRows]);

  function handleItemsToDelete(items) {
    setItemsToDelete(items);
  }

  const deleteEntries = useCallback(async (rows) => {
    let auth = btoa(props.username + ":" + props.password);
    const header = new Headers();
    header.append("Authorization", "Basic " + auth);
    header.append("Content-Type", "application/json");
    try {
      let guids = rows.join(",");
      let jsonData = JSON.stringify({
        "url": "https://portal.wood-in-vision.com/api/v1/poi/" + guids,
        "systemAuthToken": auth,
      });
      let requestOptions = {
        headers: header,
        method: "DELETE",
        body: jsonData,
      };
      await new Promise((resolve) => {
        callApiAsync(BACKEND_URL + "/api/Fetcher/DeleteByBridge", requestOptions, (response) => {
          if(response.status == 204) {
            setItemsToDelete([]);
            props.onTableRowsChanged(selectedPoiConfig.config.namespace + "/" + selectedPoiConfig.config.containerId, rows, "delete");
          }
          resolve()
        });
      });
    } catch (error) {
      setItemsToDelete([]);
    }
  }, [dataGridRef, props.selectedTableRows]);

  const getRowClassName = useCallback(({ id }) => {
    const unsavedRow = unsavedChangesRef.current.unsavedRows[id];
    if (unsavedRow) {
      return 'row--edited';
    }
    return '';
  }, []);


  function renderHeader(params) {
    let headerText = formatText(params.colDef ? params.colDef.headerName : "");
    return(headerText);
  }

  function valueGetterColumn(value, column, row) {
    //Timestamp
    if(column.type == "string" && column.id == "_date") {
      let date = new Date(row ? row.std.ts : null);
      return(date.toLocaleString());
    }

    //Lat Lon
    if(column.type == "html" && column.id == "_latlon") {
      let locString = (row && row.std.loc && row.std.loc.lat && !isNaN(row.std.loc.lat) ? row.std.loc.lat.toFixed(4) : null) + " / " + (row && row.std.loc && row.std.loc.lon && !isNaN(row.std.loc.lon) ? row.std.loc.lon.toFixed(4) : null);
      return(locString);
    }
  
    //Mts
    if(column.type == "datetime" && column.id == "_mts") {
      let date = new Date(row && row.std.mts ? row.std.mts : null);
      return(date);
    }

    //LoginName
    if(column.type == "string" && column.id == "_loginName") {
      return(row.std[column.id]);
    }

    //LoginName
    if(column.type == "string" && column.id == "_modifiedBy") {
      return(row.std["modifiedBy"]);
    }

    //Address
    if((column.type == "string" || column.type == "GeocodingInfo") && column.id == "_address") {
      let addressString = "";
      addressString += row.poi && row.poi[column.id] && row.poi[column.id].state ? row.poi[column.id].state : "";
      addressString += row.poi && row.poi[column.id] && row.poi[column.id].street ? ", " + row.poi[column.id].street: "";
      addressString += row.poi && row.poi[column.id] && row.poi[column.id].postalCode ? ", " + row.poi[column.id].postalCode : "";
      addressString += row.poi && row.poi[column.id] && row.poi[column.id].village ? " " + row.poi[column.id].village : "";
      return(addressString);
    }

    switch(column.type) {
      case "int":
      case "float":
        let fixedValue = 2;
        if(column.maximumFractionDigits) {
          fixedValue = column.maximumFractionDigits;
        }
        return(!isNaN(parseFloat(row.poi && row.poi[column.id] ? row.poi[column.id] : null)) && column.type != "int" ? parseFloat(row.poi && row.poi[column.id] ? row.poi[column.id] : null).toFixed(fixedValue) : row.poi && row.poi[column.id] ? row.poi[column.id] : null);

      case "string":
      case "stringMultiLine":
        if(column.id != "_address") {
          return(row.poi && row.poi[column.id] ? row.poi[column.id] : null);
        } else {
          return("");
        }

      case "email":
        return(row.poi && row.poi[column.id] ? row.poi[column.id] : null);

      case "bool":
        return(row.poi && row.poi[column.id] ? row.poi[column.id] : null);

      case "date":
        return(row.poi && row.poi[column.id] ? new Date(row.poi[column.id]) : null);
      
      case "datetime":
        return(row.poi && row.poi[column.id] ? new Date(row.poi[column.id]) : null);

      case "list":
        return(row.poi && row.poi[column.id] ? row.poi[column.id] : null);

      case "list_multiselect":
        return(row.poi && row.poi[column.id] ? row.poi[column.id] : null);

      case "button":
        return(column.value);

      case "vectorOverlay":
        return(row.poi && row.poi[column.id] ? row.poi[column.id] : null);

      case "image":
        return(row.poi && row.poi[column.id] ? row.poi[column.id] : null);

      case "document":
        return(row.poi && row.poi[column.id] ? row.poi[column.id] : null);

      case "vcard":
        return(row.poi && row.poi[column.id] ? row.poi[column.id] : null);

      case "label":
        return(row.poi && row.poi[column.id] ? row.poi[column.id] : null);

      default:
        return(row.poi && row.poi[column.id] ? row.poi[column.id] : null);

    }
  }

  function valueSetterColumn(params, column, row) {
    return {...row, poi: {...row.poi, [column.id]: params }};
  }

  function renderCell(params, column) {
    if(params.field == "_oid") {
      return(params.row.std.guid);
    }

    if(params.field == "_rawdata") {
      return(JSON.stringify(params.row, null, 2));
    }

    switch(column.type) {
      case "int":
      case "float":
        return(params.value);

      case "string":
      case "stringMultiLine":
      case "time":
        return(params.value);

      /*case "stringMultiLine":
        return(<GridCellExpand value={params.value || ''} width={params.colDef.computedWidth} />);*/

      case "link":
        return(
          <>
            {params.value ? <Tooltip title={"Link öffnen"} arrow placement='top'><IconButton style={{verticalAlign: "sub"}} onClick={(e) => {openUrl(params.value, e); e.stopPropagation(); }}><OpenInNew sx={{fontSize: "18px"}}/></IconButton></Tooltip> : null}
            {params.value && params.value ? params.value : ""}
          </>
        );

      case "email":
        return(
          <>
            {params.value ? <Tooltip title={"In Zwischenablage kopieren"} arrow placement='top'><IconButton style={{verticalAlign: "sub"}} onClick={(e) => {navigator.clipboard.writeText(params.value); e.stopPropagation(); }}><ContentCopyOutlined sx={{fontSize: "18px"}}/></IconButton></Tooltip> : null}
            {params.value && params.value ? params.value : ""}
          </>
        );

      case "bool":
        return(<Checkbox size='small' disabled checked={params.value}/>);

      case "date":
        return(params.formattedValue);
      
      case "datetime":
        return(params.formattedValue);

      case "list":
        let objSingle = column.list ? column.list.sort((a, b) => a.key - b.key).find((option) => option.key == params.value) : ""
        return(objSingle ? objSingle.value : params.value);

      case "list_multiselect":
        let keys = params.value && typeof(params.value) == "string" ? params.value.split(";") : [];
        return(
          keys.map((entry, entryIdx) => {
            let objSingle = column.list ? column.list.sort((a, b) => a.key - b.key).find((option) => option.key == entry) : ""
            return(
              <Chip key={column.id + params.id + entryIdx} size='small' color={column.id == "send_to" ? "primary" : "default"} label={objSingle ? objSingle.value : entry} style={{marginInline: "2px"}}></Chip>
            );
          })
        );

      case "button":
        return(<Button size='small' variant='outlined' onClick={(e) => e.preventDefault()}>{params.value}</Button>);

      case "vectorOverlay":
        return(
          <>
            {params.value ? <Tooltip title={"Geometrien herunterladen"} arrow placement='top'><IconButton style={{verticalAlign: "sub"}} onClick={(e) => handleFileDownload(params.value, props.username, props.password, e)}><Download sx={{fontSize: "18px"}}/></IconButton></Tooltip> : null}
            {params.value && params.value.l ? params.value.l : ""}
          </>
        );

      case "image":
        return(
          <>
            {params.value ? <Tooltip title={"Bild anzeigen"} arrow placement='top'><IconButton style={{verticalAlign: "sub"}} onClick={(e) => handleFileDownload(params.value, props.username, props.password, e)}><ImageIcon sx={{fontSize: "18px"}}/></IconButton></Tooltip> : null}
            {params.value ? params.value.l : ""}
          </>
        );

      case "signature":
        return(
          <>
            {params.value ? <Tooltip title={"Unterschrift herunterladen"} arrow placement='top'><IconButton style={{verticalAlign: "sub"}} onClick={(e) => handleFileDownload(params.value, props.username, props.password, e)}><ImageIcon sx={{fontSize: "18px"}}/></IconButton></Tooltip> : null}
            {params.value ? "" : ""}
          </>
        );

      case "document":
        return(
          <div style={{display: "flex"}}>
            {params.value ? <Tooltip title={"Dokument herunterladen"} arrow placement='top'><IconButton style={{verticalAlign: "sub"}} onClick={(e) => handleFileDownload(params.value, props.username, props.password, e)}><Download sx={{fontSize: "18px"}}/></IconButton></Tooltip> : null}
            {params.value ? params.value.l : ""}
          </div>
        );

      case "vcard":
        return(
          <div style={{display: "flex"}}>
            {params.value ? <Tooltip title={"Herunterladen"} arrow placement='top'><IconButton style={{verticalAlign: "sub"}} onClick={(e) => handleFileDownload(params.value, props.username, props.password, e)}><Download sx={{fontSize: "18px"}}/></IconButton></Tooltip> : null}
            {params.value && params.value.l ? <Typography fontSize={14} style={{alignContent: "center", cursor: "pointer"}} onClick={(e) => openUrl("https://portal.wood-in-vision.com/api/v1/blob/" + params.value.guid, e)}>{params.value.l}</Typography> : ""}
          </div>
        );

      case "html":
        return(params.value);

      case "label":
        return(params.value);

      default:
        return(params.value);
    }
  }

  function renderEditCell(params, column) {
    if(params.field == "_oid") {
      return(params.row._id.$oid);
    }

    if(params.field == "_rawdata") {
      return(JSON.stringify(params.row, null, 2));
    }

    if(params.field == "_latlon") {
      props.setMoveEntry(true);
      dataGridRef.current.stopCellEditMode({id: params.id, field: params.field});
      return(<GridEditInputCell {...params}/>);
    }

    switch(column.type) {
      case "int":
        return(<GridEditInputCell {...params}/>);

      case "list":
        return(
          <CustomSingleSelectEditComponent {...params} column={column}/>
        );

      case "list_multiselect":
        if(column.id == "send_to") {
          return(<CustomMultiSelectEditComponent {...params} column={column} freeSolo={true}/>);
        } else {
          return(<CustomMultiSelectEditComponent {...params} column={column}/>);
        }

      case "bool":
        return(
          <CustomBooleanEditComponent {...params} column={column}/>
        );

      case "date":
      case "datetime":
        return(
          <GridEditDateCell {...params} column={column}/>
        );

      case "button":
        return(<Button size='small' variant='outlined' onClick={(e) => e.preventDefault()}>{params.value}</Button>);

      case "vectorOverlay":
        return(<CustomVectorOverlayEditComponent {...params} username={props.username} password={props.password} column={column} accept={"application/vnd.google-earth.kml+xml"}/>);

      case "document":
        return(<CustomFileEditComponent {...params} username={props.username} password={props.password} column={column} accept={"application/pdf, application/vnd.oasis.opendocument.text, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, audio/wav, audio/mpeg, image/apng, image/jpeg, image/gif"}/>);

      case "image":
        return(<CustomImageEditComponent {...params} username={props.username} password={props.password} column={column} accept={"image/jpeg, image/png, image/gif"}/>);

      default:
        return(<GridEditInputCell {...params}/>);
    }
  }

  function handleOnFilterModelChange(filterModel) {
    onFilterModelChange(poiUniqueId, filterModel);
  }

  function handleOnRowSelectionModelChange(row) {
    onRowSelectionModelChange(poiUniqueId, row);
  }

  function generateExportFileName() {
    return props.selectedPoiConfig != null ? props.selectedPoiConfig.config.containerTitle : "Neue Tabelle";
  }

  const handleCellEditStop = (params, event) => {
    if (params.field == "_latlon" && props.moveEntry) {
      event.defaultMuiPrevented = true;
    }
  };

  useEffect(() => {
    if(props.selectedTableRows.length == 1) {
      let id = props.selectedTableRows[0];
      if(props.appView) {
        setRowModesModel({[id]: { mode: GridRowModes.Edit }});
      } else {
        setRowModesModel({[id]: { mode: GridRowModes.View }});
      }
    }
  }, [props.selectedTableRows, props.appView]);


  let selectedPoiList = props.poiList != null && props.selectedPoiConfig != null ? props.poiList[props.selectedPoiConfig.config.namespace + "/" + props.selectedPoiConfig.config.containerId] : null;

  let columns = useMemo(() => {
    let sendToColumn;
    let columns = [];

    if(props.selectedPoiConfig) {
      for(let column of props.selectedPoiConfig.config.rows) {
        let visSetting = column && column["ui.web"] ? column["ui.web"].find((entry) => entry.key == "visible") : null;
        let visible = visSetting ? visSetting.value == "true" ? true : false : true;

        if(column.type == "uiPoiInPoi") {
          visible = false;
        }

        let editSetting = column && column["ui.web"] ? column["ui.web"].find((entry) => entry.key == "editable") : null;
        let editable = editSetting ? editSetting.value == "true" ? true : false : true;

        let newColumn = {
          field: column.id,
          type: muiTypes[column.type],
          headerName: column.label,
          flex: 1,
          minWidth: column.id == "send_to" ? 200 : 150,
          editable: editable,
          pinnable: false,
          groupable: false,
          valueOptions: column.list ? column.list : [],
          getOptionLabel: (value) => value.value,
          getOptionValue: (value) => value.key,
          renderCell: (params) => renderCell(params, column),
          //renderCell: (params) => renderEditCell(params, column),
          renderEditCell: (params) => renderEditCell(params, column),
          valueGetter: (params, row) => valueGetterColumn(params, column, row),
          valueSetter: (params, row) => valueSetterColumn(params, column, row),
          renderHeader: renderHeader
        }

        if(newColumn.field == "send_to") {
          sendToColumn = newColumn;
        } else {
          if(visible) columns.push(newColumn);
        }
      }
    }

    let guidColumn = {
      field: "_oid",
      type: "string",
      headerName: "ObjectID",
      width: 150,
      editable: false,
      renderCell: (params) => renderCell(params)
    }
    columns.push(guidColumn);

    let rawDataColumn = {
      field: "_rawdata",
      type: "string",
      headerName: "JSON Data",
      width: 150,
      editable: false,
      renderCell: (params) => renderCell(params)
    }
    columns.push(rawDataColumn);

    if(sendToColumn != null) columns.splice(0, 0, sendToColumn);

    return(columns);
  }, [props.selectedPoiConfig]);

  let rows = useMemo(() => (
    selectedPoiList != null ? selectedPoiList.map((poi, idx) => {
    var data = poi;
    data["id"] = poi.std.guid;
    return data;
  }) : []
), [props.poiList, props.selectedPoiConfig, selectedPoiList]);

  let selectedTableRowPois = useMemo(() => {
    return selectedPoiList?.filter((poi,i) => props.selectedTableRows?.includes(poi.id))
  },[props.poiList, props.selectedPoiConfig, selectedPoiList, props.selectedTableRows])

  const { dispatch } = useSelectedPoiList();
  useEffect(() => {
    dispatch({ type: SelectedPoiActions.setSelectedPoi, payload: selectedTableRowPois });
    dispatch({ type: SelectedPoiActions.setSelectedConfigs, payload: props.selectedPoiConfig?.config });
  },[props.poiList, selectedTableRowPois, selectedPoiList, dispatch, props.selectedPoiConfig])

  let parentGuids = useMemo(() => {return(props.selectedPoiConfig ? selectedRows[selectedPoiConfig.parentUniqueId] : [])}, [props.selectedPoiConfig, selectedRows]);

  let poiUniqueId = props.selectedPoiConfig ? props.selectedPoiConfig.config.namespace + "/" + props.selectedPoiConfig.config.containerId : "";

  let statisticData = useMemo(() => {
    let obj = {}
    let sum = 0;
    let cnt = selectedTableRowPois ? selectedTableRowPois.length : 0;

    if(selectedTableRowPois && selectedColumn) {
      selectedTableRowPois.map((row) => {
        sum += !isNaN(parseFloat(row.poi[selectedColumn.field])) ? parseFloat(row.poi[selectedColumn.field]) : 0;
      });
    }
    
    obj["Anzahl"] = cnt;
    obj["Summe"] = sum.toFixed(2);
    obj["Querschnitt"] = cnt > 0 ? (sum/cnt).toFixed(2) : 0;

    return(obj);
  }, [showStatistic]);

  return(
    <Box sx={{ height: (props.height-40), width: "calc(100vw - " + (70) + "px)", backgroundColor: theme.palette.background.default, overflow: "hidden"}}>
      {renderConfirmDialog()}
      <DataGridPremium
        apiRef={dataGridRef}
        rows={rows}
        columns={columns}

         //CELL SELECTION NOT WORKING
        rowSelectionModel={props.selectedTableRows}
        //cellSelection

        filterModel={props.selectedTableFilters}
        onFilterModelChange={handleOnFilterModelChange}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
          pinnedColumns: { 
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field]
          },
          density: "compact"
        }}
        slots={{
          toolbar: CustomToolbar,
          loadingOverlay: LinearProgress,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        slotProps={{
          toolbar: {
            configs,
            poiUniqueId,
            username,
            gisStyles,
            selectedPoiConfig,
            poiList,
            selectedRows,
            selectedTableRows,
            parentGuids,
            selectedStyle,
            selectedMapStyle,
            companyLogo,
            mapConfig,
            itemsToDelete,
            userSettings,
            selectedPoisByUniqueId,
            handleItemsToDelete,
            onMapConfigChanged,
            disableButtons,
            onGisStyleChanged,
            unsavedChangesRef,
            createEntry,
            discardChanges,
            handleUndo,
            handleRedo,
            saveChanges,
            editGeometry,
            onEditGeometry,
            deleteEntriesDialog,
            onRowSelectionModelChange,
            deleteEntries,
            hasUnsavedRows,
            isSaving,
            dataGridRef,
            csvOptions: { fileName: generateExportFileName() }, 
            printOptions: { fileName: generateExportFileName() }
          },
          cell: {
            onContextMenu: handleContextMenu,
          },
        }}
        style={{
          borderStyle: "none"
        }}
        //cellSelection
        disableAggregation
        disableRowGrouping
        disableRowSelectionOnClick={props.editGeometry}
        loading={props.loading}
        pageSizeOptions={[100]}
        checkboxSelection={!props.appView}
        rowModesModel={rowModesModel}
        onRowSelectionModelChange={!(props.serverSyncState > 0) ? (handleOnRowSelectionModelChange ? handleOnRowSelectionModelChange : null) : null} //If geometries syncing disable select
        processRowUpdate={processRowUpdate}
        onProcessRowUpdateError={(e) => console.log(e)}
        getRowClassName={getRowClassName}
        onRowClick={(row, event) => {
          if(!props.editGeometry && !(props.serverSyncState > 0)) {
            props.onRowSelectionModelChange(poiUniqueId, [row.id]);
          }
          event.defaultMuiPrevented = true;
          event.stopPropagation = true;
        }}
        //onCellEditStop={handleCellEditStop}
        autosizeOptions={{
          includeHeaders: false,
          includeOutliers: true,
          expand: true
        }}
        sx={{
          '& .MuiDataGrid-row:hover': {
            cursor: 'pointer'
          },
          [`& .${gridClasses.row}.row--removed`]: {
            backgroundColor: (theme) => {
              if (theme.palette.mode === 'light') {
                return 'rgba(255, 170, 170, 0.3)';
              }
              return darken('rgba(255, 170, 170, 1)', 0.7);
            },
          },
          [`& .${gridClasses.row}.row--edited`]: {
            backgroundColor: (theme) => {
              if (theme.palette.mode === 'light') {
                return 'rgba(255, 254, 176, 0.3)';
              }
              return darken('rgba(255, 254, 176, 1)', 0.6);
            },
          },
          [`& .${gridClasses.row}.row--edited:hover`]: {
            backgroundColor: (theme) => {
              if (theme.palette.mode === 'light') {
                return 'rgba(255, 254, 176, 0.6)';
              }
              return darken('rgba(255, 254, 176, 1)', 0.7);
            },
          },
          [`& .MuiDataGrid-cell input`]: {
            paddingInline: "10px",
          },
          [`& .MuiDataGrid-cell.${gridClasses['cell--editing']}`]: {
            boxShadow: "0px 0px 0px 0px rgba(0, 0, 0, 0.2)"
          }
        }}
      />
      <Menu
        open={contextMenu !== null}
        onClose={() => setContextMenu(null)}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        slotProps={{
          root: {
            onContextMenu: (e) => {
              e.preventDefault();
              setContextMenu(null);
            }
          }
        }}
      >
        <MenuItem onClick={() => {setContextMenu(null); setShowDetails(true);}}>Detailansicht</MenuItem>
        <MenuItem onClick={() => {navigator.clipboard.writeText(selectedRow.textContent); setContextMenu(null);}}>Kopieren</MenuItem>
        {selectedColumn?.type === "number" ?
          <MenuItem onClick={() => {setContextMenu(null); setShowStatistic(true);}}>Statistik</MenuItem>
          :
          null
        }
      </Menu>
      <Dialog fullWidth maxWidth="sm" open={showDetails || showStatistic}>
        <DialogTitle>{showDetails ? "Detailansicht" : "Statistik - " + selectedColumn?.headerName}{showDetails ? <IconButton onClick={() => navigator.clipboard.writeText(selectedRow.textContent)}><CopyAll/></IconButton> : null}</DialogTitle>
        <DialogContent dividers>
          {showDetails ?
            <TextField multiline fullWidth rows={10} value={selectedRow ? selectedRow.textContent : ""}/>
            :
            <div>
              <TableContainer>
                <Table size="small" aria-label="simple table">
                  <TableBody>
                    {Object.entries(statisticData).map(([key, value]) => (
                      <TableRow key={key} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell style={{userSelect: "none", overflow: "hidden", width: "50%" }} align="left">{key}</TableCell>
                        <TableCell style={{userSelect: "none", width: "50%"}} align="left">{ (typeof value) == "object" ? JSON.stringify(value) : value}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            setShowDetails(false);
            setShowStatistic(false);
          }}>Ok</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}