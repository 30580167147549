import "../../App.css";
import "./MapView.css";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import PoiShard from "../../widgets/appView/PoiShard";
import { useResizeObserver } from "../../helper/ResizeObserver";
import PoiConfigSelectionView from "../../widgets/appView/PoiConfigSelectionView";
import PoiSelectionListView from "../../widgets/appView/PoiSelectionListView";
import BasicMap from "../../widgets/basicMap/BasicMap";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, LinearProgress, Slide, Snackbar, Tooltip, Typography } from '@mui/material';
import { getSimpleLabel } from "../../js/helper";
import { Add } from "@mui/icons-material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TabViewParentItem from "../../widgets/webView/TabViewParentItem";
import TabViewChildItem from "../../widgets/webView/TabViewChildItem";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { BACKEND_URL } from "../../js/defines";
import { useTheme } from "@emotion/react";
import TabViewTable from "../../widgets/webView/TabViewTable";
import default_config_layer from "../../poiConfigs/default_config_layer.json";
import WIV_Logo from "../../assets/WIV_Logo.png";
import { BarChart } from '@mui/x-charts/BarChart';
import { useMap } from "../../context/MapProvider";

function InfoCharts(props) {
  const theme = useTheme();
  
  let treeList = props.poiList && props.poiList["global/mechanical_timber_list"] ? props.poiList["global/mechanical_timber_list"] : [];

  let volType = "log_volume";
  var filteredListVolume = []
  var filterListCount = []
  var totalVolume = 0;
  var totalCnt = 0;
  treeList.forEach(element => {
    if(element.poi.log_type != "Unclassified") {
      var key = element.poi.log_species + " " + element.poi.log_type
      if (filteredListVolume[key] == null) {
        filteredListVolume[key] = 0;
      }
      if (filterListCount[key] == null) {
        filterListCount[key] = 0;
      }

      totalVolume += (element.poi[volType] * 0.0001)
      totalCnt += 1
      filteredListVolume[key] = filteredListVolume[key] + (element.poi[volType] * 0.0001)
      filterListCount[key] = filterListCount[key] + 1
    }
  });
  
  //Generate bar chart data
  var barSeries = []
  var barData = []
  Object.entries(filteredListVolume).sort((a, b) => parseFloat(b[1]) - parseFloat(a[1])).map((row) => { 
    barSeries.push(row[0])
    barData.push(row[1].toFixed(2))
  })

  return (
    <>
      <div
        style={{
          position: "absolute",
          display: "flex",
          padding: "10px",
          flexDirection: "column",
          width: "300px",
          backgroundColor: theme.palette.background.default,
          borderRadius: "20px",
          color: "white",
          left: "86px",
          bottom: props.tableHeight + 16,
          visibility: props.diagrammOpen ? "visible" : "hidden",
          zIndex: 1,
        }}
      >
        <div style={{ display: "flex" }}>
          <Typography style={{ color: theme.palette.text.primary, alignSelf: "center", fontSize: 18, fontWeight: 600 }}>
            Diagramm
          </Typography>
        </div>

        <BarChart
          xAxis={[
            {
              id: 'barCategories',
              data: barSeries,
              scaleType: 'band',
            },
          ]}
          series={[
            {
              data: barData,
              color: theme.palette.primary.main
            },
          ]}
          width={300}
          height={300}
        />

        <Typography style={{ color: theme.palette.text.primary, fontSize: 18, fontWeight: 600 }}>
          FM: {totalVolume.toFixed(3)}
        </Typography>

        <Typography style={{ color: theme.palette.text.primary, fontSize: 18, fontWeight: 600 }}>
          Anzahl: {totalCnt}
        </Typography>

      </div>
    </>
  );
}

function MapView(props) {
  const content = useRef(null);
  const theme = useTheme();
  useResizeObserver(content);

  const [appView, setAppView] = useState(false);
  const [diagrammOpen, setDiagrammOpen] = useState(false);
  const { mapData } = useMap();
  const [saveOpen, setSaveOpen] = useState(false);
  const [getWidth, setGetWidth] = useState(0);
  const [editGeometry, setEditGeometry] = useState(false);
  const [moveEntry, setMoveEntry] = useState(false);
  const [snackMessage, setSnackMessage] = useState("");

  const [heightState, setHeightState] = useState(props.userSettings && props.userSettings.heightState != null ? props.userSettings.heightState : 1);
  const [minimizedHeightState, setMinimizedHeightState] = useState(null);
  const [height, setHeight] = useState(window.innerHeight / 3);

  const [cfgList, setCfgList] = useState(null); //Available Tabs
  const [activeCfgs, setActiveCfgs] = useState([]); //Open Tabs
  const [selectedCfg, setSelectedCfg] = useState(null); //Active Tab
  const [gisStylesArr, setGisStyles] = useState({}); //Available Styles
  const [selectedStyle, setSelectedStyle] = useState({}); //Active Styles
  
  const [poiList, setPoiList] = useState({}); //Open Poi Entries
  const [mapConfig, setMapConfig] = useState({}); //Map Config - Show Icon - Show Vector

  const [selectedPois, setSelectedPois] = useState({}); //Open Selected Poi Entries
  const [selectedBlobs, setSelectedBlobs] = useState({}); //Open Selected Poi Blobs

  const [selectedRows, setSelectedRows]= useState({}); //Open Selected Poi Rows

  const [selectedFilters, setSelectedFilters]= useState({}); //Open Selected Poi Filters

  const [layerArr, /*setLayerArr*/]= useState(props.userSettings && props.userSettings.mapLayers ? typeof(props.userSettings.mapLayers) === "string" ? JSON.parse(props.userSettings.mapLayers) : props.userSettings.mapLayers : default_config_layer); //Open Selected Poi Rows
  const [favLayerArr, setFavLayerArr]= useState(props.userSettings && props.userSettings.favLayers ? typeof(props.userSettings.favLayers) === "string" ? JSON.parse(props.userSettings.favLayers) : props.userSettings.favLayers : []); //Open Selected Poi Rows
  const [enabledBaseLayer, setEnabledBaseLayer]= useState(props.userSettings && props.userSettings.enabledBaseLayer ? props.userSettings.enabledBaseLayer : "osm"); //Open Selected Poi Rows
  const [enabledLayers, setEnabledLayers]= useState(props.userSettings && props.userSettings.enabledLayers ? props.userSettings.enabledLayers : []); //Open Selected Poi Rows
  const [enabledFavLayer, setEnabledFavLayer]= useState(props.userSettings && props.userSettings.enabledFavLayer ? props.userSettings.enabledFavLayer : ""); //Open Selected Poi Rows

  const [companyLogo, setCompanyLogo]= useState(props.userSettings && props.userSettings.companyLogo ? props.userSettings.companyLogo : WIV_Logo); //Open Selected Poi Rows

  const [serverSyncState, setServerSyncState] = useState(0);
  const [updateIndex, setUpdateIndex] = useState(0);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function getPoiUniqueId() {
    return selectedCfg ? selectedCfg.config.namespace + "/" + selectedCfg.config.containerId : "";
  }

  //Old AppView
  const createList = (poiConfigs) => {
    let groups = [];
    for (let poiConfig of poiConfigs) {
      let poiGroupName = poiConfig.config["label"].split("|")[0];
      let oldGroup = groups.find(
        (group) => group["groupName"] === poiGroupName
      );
      if (oldGroup == null && poiGroupName != null) {
        let newGroup = {
          groupName: poiGroupName,
          open: false,
          pois: [],
        };
        groups.push(newGroup);
        oldGroup = newGroup;
      }
      oldGroup["pois"].push({
        poiUniqueId: poiConfig.config["namespace"] + "/" + poiConfig.config["containerId"],
        label: poiConfig.config["label"].split("|")[1],
        containerChooseIconBase64: poiConfig.config["containerChooseIconBase64"],
      });
    }
    setCfgList(groups);
  };

  function switchFolder(idx) {
    var newPoiConfigList = [...cfgList];
    newPoiConfigList[idx]["open"] = !newPoiConfigList[idx]["open"];
    setCfgList(newPoiConfigList);
  }

  function selectCurrentPoiConfig(poiConfig) {
    setSelectedCfg(poiConfig);
    updatePoiList(poiConfig);
  }

  function updatePoiList(poiConfig, newSelectedRows) {
    if (poiConfig != null) {
      let innerSelectedRows = newSelectedRows ? newSelectedRows : selectedRows[poiConfig.parentUniqueId];

      //Clear child table if no parent selected
      if((innerSelectedRows == null || (innerSelectedRows != null && innerSelectedRows?.length == 0)) && poiConfig.parentUniqueId != null) {
        var newPoiList = {...poiList};
        newPoiList[poiConfig.config["namespace"] + "/" + poiConfig.config["containerId"]] = [];
        setPoiList(newPoiList);
        return;
      }

      if(innerSelectedRows != null && innerSelectedRows.length > 0) {
        let filter = "$.parent.std.guid==\"" + innerSelectedRows[0] + "\""
        for(let cnt in innerSelectedRows) {
          if(cnt != 0) {
            filter += "||$.parent.std.guid==\"" + innerSelectedRows[cnt] + "\""
          }
        }
        getPoiListFromServer(poiConfig.config["namespace"] + "/" + poiConfig.config["containerId"], filter);
      } else {
        getPoiListFromServer(poiConfig.config["namespace"] + "/" + poiConfig.config["containerId"]);
      }

      let poiMapConfig = mapConfig[poiConfig.config.namespace + "/" + poiConfig.config.containerId];
      if(poiMapConfig == null) {
        mapConfig[poiConfig.config.namespace + "/" + poiConfig.config.containerId] = {
          showIcons: false,
          showLayer: false
        }
        setMapConfig(mapConfig);
      }
    }
  }

  function onListViewBack() {
    setPoiList({});
    //setUpdateIndex(updateIndex + 1);
    setSelectedCfg(null);
  }

  function openPoiShard(poi) {
    console.log(selectedPois)
    let newSelectedPois = {...selectedPois};
    if(newSelectedPois[selectedCfg.config["namespace"] + "/" + selectedCfg.config["containerId"]] == null) {
      newSelectedPois[selectedCfg.config["namespace"] + "/" + selectedCfg.config["containerId"]] = []
    }
    newSelectedPois[selectedCfg.config["namespace"] + "/" + selectedCfg.config["containerId"]] = [poi];
    setSelectedPois(newSelectedPois);

    if (poi["poi"] != null && poi["poi"]["x_map"] != null) {
      let blobGuid = poi["poi"]["x_map"]["guid"];
      getBlobFromServer(selectedCfg.config["namespace"] + "/" + selectedCfg.config["containerId"], blobGuid);
    }

    console.log(newSelectedPois)
  }


  
  //New AppView - DetailView
  function openPoiShardFromTable(poi, row) {
    handleSelectedRows(poi.std.poiUniqueId, [row.id])
  }

  function onPoiShardBack() {
    setAppView(false);
    /*let newSelPois = {...selectedPois};
    delete newSelPois[selectedCfg.config.namespace + "/" + selectedCfg.config.containerId];
    setSelectedPois(newSelPois);
    setSelectedBlobs([]);*/
  }

  function onPoiShardSave() {
    setAppView(false);
    //setWidth(0);
    //setSelectedPois(null);

    let newSelPois = {...selectedPois};
    delete newSelPois[selectedCfg.config.namespace + "/" + selectedCfg.config.containerId];
    setSelectedPois(newSelPois);
  }



  //WebView - TableView
  function tableRowsChanged(poiUniqueId, rows, action) {
    let newPoiList = {...poiList};
    if(action == "delete") {
      rows.forEach((guid) => {
        let foundIdx = newPoiList[poiUniqueId].findIndex((item) => item.std.guid == guid);
        console.log(foundIdx);
        if(foundIdx > -1) {
          newPoiList[poiUniqueId].splice(foundIdx, 1);
        }
      });
      setPoiList(newPoiList);
    } else {
      rows.forEach((row) => {
        let foundIdx = newPoiList[poiUniqueId].findIndex((item) => item.std.guid == row.std.guid);
        if(foundIdx > -1) {
          newPoiList[poiUniqueId][foundIdx] = row;
        } else {
          newPoiList[poiUniqueId].push(row);
        }
      });
      setPoiList(newPoiList);
    }
  }


  const geometryChanged = useCallback((kmlString) => {
    console.log(kmlString);
  });

  function onPoiAddedToTabView(config) {
    if(config != null) {
      let newSelectedPoiConfigs = activeCfgs;

      //Add it to the list
      if(!newSelectedPoiConfigs.includes(config)) {
        newSelectedPoiConfigs.push(config);
        setActiveCfgs(newSelectedPoiConfigs);
      }

      selectCurrentPoiConfig(config);
    }
    setAnchorEl(null);
  };

  function onPoiRemovedFromTabView(config) {
    if(config != null) {
      var newSelectedConfigs = activeCfgs.filter(innerConfig => (innerConfig.config.namespace + "/" + innerConfig.config.containerId) !== (config.config.namespace + "/" + config.config.containerId));
      setActiveCfgs(newSelectedConfigs);

      //RESET ALL VALUES
      let newSelPois = {...selectedPois};
      newSelPois[config.config.namespace + "/" + config.config.containerId] = [];
      setSelectedPois(newSelPois);

      let newSelBlobs = {...selectedBlobs};
      newSelBlobs[config.config.namespace + "/" + config.config.containerId] = [];
      setSelectedBlobs(newSelBlobs);

      let newSelRows = {...selectedRows};
      newSelRows[config.config.namespace + "/" + config.config.containerId] = [];
      setSelectedRows(newSelRows);

      let newPoiList = {...poiList};
      delete newPoiList[config.config.namespace + "/" + config.config.containerId];
      setPoiList(newPoiList);
                
      if(selectedCfg === config) {
        setSelectedCfg(null);
        setAppView(false);
      }
    }
  };



  //Map Handlers
  function onGisStyleChanged(poiUniqueId, guid) {
    var newSelectedStyle = {...selectedStyle};
    if(newSelectedStyle[poiUniqueId] === guid) {
      newSelectedStyle[poiUniqueId] = null;
    } else {
      newSelectedStyle[poiUniqueId] = guid;
    }
    setSelectedStyle(newSelectedStyle);
  }

  function onMapConfigChanged(poiUniqueId, type, value) {
    var newMapConfig = {...mapConfig};
    if(newMapConfig[poiUniqueId] == null) {
      newMapConfig[poiUniqueId] = {
        showIcons: false,
        showLayer: false
      }
    }
    newMapConfig[poiUniqueId][type] = value;
    if(value === true) {
      handleSelectedRows(poiUniqueId, selectedRows[poiUniqueId] ? selectedRows[poiUniqueId] : []);
    } else {
      if(type === "showLayer") {
        let newSelectedBlobs = {...selectedBlobs};
        if(newSelectedBlobs[poiUniqueId] == null) {
          newSelectedBlobs[poiUniqueId] = []
        }
        newSelectedBlobs[poiUniqueId] = [];
        setSelectedBlobs(newSelectedBlobs);
      }
    }
    setMapConfig(newMapConfig);
  }

  const onNewFavorit = useCallback((newFavorit) => {
    let newFavLayerArr = [...favLayerArr];
    newFavLayerArr.push(newFavorit);
    setFavLayerArr(newFavLayerArr);
    props.onSaveUserSetting("favLayers", newFavLayerArr);
  }, [favLayerArr]);

  const onBaseLayerChanged = useCallback((key, value) => {
    setEnabledBaseLayer(key);
    props.onSaveUserSetting("enabledBaseLayer", key);
    setEnabledFavLayer(null);
  }, [enabledBaseLayer])

  const onFavLayerChanged = useCallback((favorit, value) => {
    setEnabledFavLayer(favorit.id);
    setEnabledLayers(favorit.enabledLayers);
    setEnabledBaseLayer(favorit.enabledBaseLayer);

    let newUserSettings = {...props.userSettings};
    newUserSettings["enabledFavLayer"] = favorit.id;
    newUserSettings["enabledBaseLayer"] = favorit.enabledBaseLayer;
    newUserSettings["enabledLayers"] = favorit.enabledLayers;
    props.onSaveCompleteUserSetting(newUserSettings);
  }, [enabledFavLayer, enabledLayers, enabledBaseLayer, props.userSettings])

  const onLayerChanged = useCallback((key, value) => {
    let newEnabledLayers = [...enabledLayers];
    let index = newEnabledLayers.findIndex((layer) => layer === key);
    if(index >= 0) {
      newEnabledLayers.splice(index, 1);
    } else {
      newEnabledLayers.push(key);
    }
    setEnabledLayers(newEnabledLayers);
    props.onSaveUserSetting("enabledLayers", newEnabledLayers);
  }, [enabledLayers])

  async function handleSelectedRows(poiUniqueId, rows) {
    let blobGuids = [];
    let newBlobGuids = [];
    let innerSelectedPois = [];
    let poiMapConfig = mapConfig[poiUniqueId] ? mapConfig[poiUniqueId] : {showIcons: false, showLayer: false};

    //Dynamic vectorOverlay setter
    let config = props.configs.find((config) => config.config.namespace + "/" + config.config.containerId == poiUniqueId);
    let vectorOverlayKey = "x_map";
    if(config) {
      let vectorOverlay = config.config.rows.find((row) => row.type == "vectorOverlay");
      if(vectorOverlay != null && vectorOverlay.id != null) vectorOverlayKey = vectorOverlay.id;
    }

    let pois = poiList[selectedCfg.config["namespace"] + "/" + selectedCfg.config["containerId"]];
    var poiDict = pois ? Object.fromEntries(pois.map(x => [x.id, x])) : pois;
    for(var rowId of rows) {
      //let poi = poiList[selectedCfg.config["namespace"] + "/" + selectedCfg.config["containerId"]].find((poi) => poi.std.guid == rowId);
      let poi = poiDict[rowId];
      if(poi != null) {
        innerSelectedPois.push(poi);
        if(poi.poi != null && poi.poi[vectorOverlayKey] != null && poi.poi[vectorOverlayKey].guid != null && poi.poi[vectorOverlayKey].guid !== "") {
          let blobGuid = poi.poi[vectorOverlayKey].guid;
          blobGuids.push(blobGuid);
          if(selectedBlobs[selectedCfg.config["namespace"] + "/" + selectedCfg.config["containerId"]] == null || selectedBlobs[selectedCfg.config["namespace"] + "/" + selectedCfg.config["containerId"]].findIndex((blob) => blob.guid === blobGuid) === -1) {
            newBlobGuids.push({
              poiGuid: poi.std.guid,
              blobGuid: blobGuid
            });
          }
        }
      }
    }

    //TEST
    /*let selectedGuids = []
    for(let rowId of rows) {
      selectedGuids.push(poiList[selectedCfg.config["namespace"] + "/" + selectedCfg.config["containerId"]][rowId].std.guid)
    }
    let filter = "$.parent.std.guid==\"" + selectedGuids[0] + "\""
    for(let cnt in selectedGuids) {
      if(cnt !== 0) {
        filter += "||$.parent.std.guid==\"" + selectedGuids[cnt] + "\""
      }
    }
    console.log("filter", filter)
    getPoiListFromServer("global/mechanical_timber_list", filter);*/

    let newSelectedRows = {...selectedRows};
    if(newSelectedRows[poiUniqueId] == null) {
      newSelectedRows[poiUniqueId] = []
    }
    newSelectedRows[poiUniqueId] = rows;
    setSelectedRows(newSelectedRows);

    let newSelectedPois = {...selectedPois};
    if(newSelectedPois[poiUniqueId] == null) {
      newSelectedPois[poiUniqueId] = []
    }
    newSelectedPois[poiUniqueId] = innerSelectedPois;
    setSelectedPois(newSelectedPois);

    if(poiMapConfig.showLayer) {
      let blobs = await getBlobsFromServer(selectedCfg.config["namespace"] + "/" + selectedCfg.config["containerId"], blobGuids, newBlobGuids);
      setSelectedBlobs(blobs);
    }
  }

  let onMapMoved = useCallback(async (mapPos) => {
    props.onSaveUserSetting("mapPos", mapPos);
  }, [props.userSettings]);


  //REST CALLS
  function getPoiListFromServer(poiUniqueId, filter) {
    let auth = btoa(props.username + ":" + props.password);
    let formData = new FormData();
    if(filter !== undefined) {
      formData.append("url", "https://portal.wood-in-vision.com/api/v1/poi?poiUniqueId=" + poiUniqueId.replace("/", "%2C") + "&filter=" + filter);
    } else {
      formData.append("url", "https://portal.wood-in-vision.com/api/v1/poi?poiUniqueId=" + poiUniqueId.replace("/", "%2C"));
    }
    formData.append("systemAuthToken", auth);

    let requestOptions = {
      method: "POST",
      body: formData
    };

    setServerSyncState(-2);
    
    let url = BACKEND_URL + "/api/Fetcher/GetByBridge";
    fetch(url, requestOptions)
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.statusCode === 200) {
        var sortedPoiList = responseJson.data.sort(
          (a, b) => Date.parse(b.std.ts) - Date.parse(a.std.ts)
        );
        var newPoiList = {...poiList};
        if(newPoiList == null) {
          newPoiList = {}
        }
        newPoiList[poiUniqueId] = sortedPoiList;
        setPoiList(newPoiList);
        //setUpdateIndex(updateIndex + 1);
        setServerSyncState(0);

        console.log(formData.get("url"), sortedPoiList.length)
      } else {
        //setUpdateIndex(updateIndex + 1);
        setServerSyncState(0);
      }
    })
    .catch((error) => {
      console.error("ERROR", error);
      //setUpdateIndex(updateIndex + 1);
      setServerSyncState(0);
    });
  }
  
  async function getBlobsFromServer(poiUniqueId, blobGuids, newBlobGuids) {
    let auth = btoa(props.username + ":" + props.password);
    const requestOptions = {
      method: "POST"
    };
    
    var newSelectedBlobs = {...selectedBlobs};

    let cnt = 0;
    let url = BACKEND_URL + "/api/Fetcher/GetByBridge";
    let percentLast = 0;

    for(let obj of newBlobGuids) {
      let formData = new FormData();
      formData.append('url',("https://portal.wood-in-vision.com/api/v1/blob/" + obj.blobGuid ));
      formData.append("systemAuthToken",  auth)
      
      //let response = await fetch("https://portal.wood-in-vision.com/api/v1/blob/" + blobGuid, requestOptions)
      let response = await fetch(url, {
        body: formData,
        ...requestOptions,
      })
      let text = await response.text();
      let blob = {
        poiguid: obj.poiGuid,
        guid: obj.blobGuid,
        kml: text
      }
      if(newSelectedBlobs[poiUniqueId] == null) {
        newSelectedBlobs[poiUniqueId] = []
      }
      newSelectedBlobs[poiUniqueId].push(blob);
      cnt++;
      let percent = (cnt / newBlobGuids.length);
      if(percent === 1) {
        percent = 0;
      }

      if(percent !== percentLast) {
        percentLast = percent.toFixed(2);
        setServerSyncState(percent.toFixed(2));
      }
      
    }

    let delta = newSelectedBlobs;
    delta[poiUniqueId] = newSelectedBlobs[poiUniqueId] ? newSelectedBlobs[poiUniqueId].filter((blob) => blobGuids.includes(blob.guid)) : [];
    return delta;
  }

  async function getGisStylesFromServer() {
    let auth = btoa(props.username + ":" + props.password);

    setServerSyncState(-1);

    const requestOptions = {
      method: "POST"
    };
    
    let formData = new FormData();
    formData.append('url',"https://portal.wood-in-vision.com/api/v1/poi?poiUniqueId=global/gisStyle&loginNamesQuery=");
    formData.append("systemAuthToken",  auth)
    let url = BACKEND_URL + "/api/Fetcher/GetByBridge";
    
    fetch(url, {
      body: formData,
      ...requestOptions,
    })
    .then((response) => response.json())
    .then(async (responseJson) => {
      var sortedPoiList = responseJson.data.sort(
        (a, b) => Date.parse(b.std.ts) - Date.parse(a.std.ts)
      );

      var newPoiList = {};
      for(var poi of sortedPoiList) {
        var metaList_uniqueId = poi.poi.metaList_poiUniqueId;
        if(newPoiList[metaList_uniqueId.replace("//x_map", "").replace("//district_boundaries", "").replace("//qrskizze", "")] === undefined) {
          newPoiList[metaList_uniqueId.replace("//x_map", "").replace("//district_boundaries", "").replace("//qrskizze", "")] = []
        }

        const formdata = new FormData();
        formdata.append("url", "https://portal.wood-in-vision.com/api/v1/poi?poiUniqueId=global/gisStyleRule&loginNamesQuery=&filter=$.parent.std.guid==\"" + poi.std.guid + "\"");
        formdata.append("systemAuthToken", auth);

        const requestChildOptions = {
          method: "POST",
          body: formdata
        };

        let url = BACKEND_URL + "/api/Fetcher/GetByBridge";

        let childResp = await fetch(url, requestChildOptions)
        let childs = await childResp.json();

        let item = {
          metaList_poiUniqueId: metaList_uniqueId,
          poi: poi,
          childs: childs.data
        }
        newPoiList[metaList_uniqueId.replace("//x_map", "").replace("//district_boundaries", "").replace("//qrskizze", "")].push(item);
      }

      setGisStyles(newPoiList);
      setServerSyncState(0);
    })
    .catch((error) => {
      console.error(error);
      setServerSyncState(0);
    });
  }

  async function getBlobFromServer(poiUniqueId, blobGuid) {
    let auth = btoa(props.username + ":" + props.password);
    let formData = new FormData();
    formData.append('url',("https://portal.wood-in-vision.com/api/v1/blob/" + blobGuid));
    formData.append("systemAuthToken",  auth)
    let url = BACKEND_URL + "/api/Fetcher/GetByBridge";
    
    const requestOptions = {
      method: "POST"
    };

    /*fetch("https://portal.wood-in-vision.com/api/v1/blob/" + blobGuid, requestOptions)*/
    fetch(url, {
      body: formData,
      ...requestOptions,
    })
      .then((response) => response.text())
      .then((kmlFile) => {
        var newSelectedBlobs = {...selectedBlobs};
        let blob = {
          guid: blobGuid,
          kml: kmlFile
        }
        newSelectedBlobs[poiUniqueId].push(blob);
        setSelectedBlobs(newSelectedBlobs);
      })
      .catch((error) => console.error(error));
  }

  
  //UI
  function getPoiMenuContent() {
    if (selectedCfg == null) {
      return (
        <PoiConfigSelectionView
          width={getWidth}
          userName={props.username}
          configs={props.configs}
          poiConfigList={cfgList}
          onFolderOpened={switchFolder}
          onPoiSelect={(poiUniqueId) => selectCurrentPoiConfig(poiUniqueId)}
        />
      );
    }
    if (selectedPois == null) {
      return (
        <PoiSelectionListView
          width={getWidth}
          userName={props.username}
          selectedPoiConfig={selectedCfg}
          updateIndex={updateIndex}
          poiList={poiList[selectedCfg.config["namespace"] + "/" + selectedCfg.config["containerId"]]}
          onOpenPoi={(poi) => openPoiShard(poi)}
          onBack={onListViewBack}
        />
      );
    }
    if (selectedCfg != null && selectedPoisByUniqueId != null) {
      return (
        <PoiShard
          updateIndex={updateIndex}
          width={getWidth}
          height={height}
          userName={props.username}
          selectedPoiConfig={selectedCfg}
          selectedPoi={selectedPoisByUniqueId ? selectedPoisByUniqueId[0] : null}
          onBack={onPoiShardBack}
          onSave={onPoiShardSave}
        />
      );
    }
  }


  //Height Control
  function handleExpand() {
    if(heightState < 4) {
      let newHeightState = heightState + 1;
      setHeightState(newHeightState);
      handleHeight(newHeightState);
      props.onSaveUserSetting("heightState", newHeightState);
      setMinimizedHeightState(null);
      
    }
  }

  function handleShrink() {
    if(heightState > 0) {
      let newHeightState = heightState - 1;
      setHeightState(newHeightState);
      handleHeight(newHeightState);
      props.onSaveUserSetting("heightState", newHeightState);
      setMinimizedHeightState(null);
    }
  }

  function toggleMaximizeTable() {
    let hState = heightState;
    let mhState = minimizedHeightState;
    if(mhState == null) {
      setMinimizedHeightState(hState);
      mhState = hState;
      setHeightState(4);
      hState = 4;
    } else {
      setHeightState(mhState);
      hState = mhState;
      setMinimizedHeightState(null);
      mhState = null;
    }
    handleHeight(hState);
  }

  const handleHeight = useCallback((newHeightState) => {
    switch(newHeightState) {
      case 0:
        setHeight(40);
        break;
      case 1:
        setHeight(window.innerHeight / 3);
        break;
      case 2:
        setHeight(window.innerHeight / 2);
        break;
      case 3:
        setHeight(window.innerHeight / 1.5);
        break;
      case 4:
        setHeight(window.innerHeight);
        break;
        default:
        setHeight(window.innerHeight);
          return
    }
  }, [heightState]);

  window.addEventListener("resize", (event) => {
    handleHeight(heightState);
  });

  useEffect(() => {
    let rows = selectedRows[getPoiUniqueId()];
    if(rows) {
      handleSelectedRows(getPoiUniqueId(), rows);
    }
  }, [poiList]);

  useEffect(() => {
    createList(props.configs);
  }, [props.configs]);

  useEffect(() => {
    getGisStylesFromServer();
    handleHeight(heightState);
  }, []);


  //LAMA RUN
  var lamaPos = 0;
  var leftToRight = true;
  function moveLama( )
  {
    if(leftToRight) {
      lamaPos += 5;
    } else {
      lamaPos -= 5;
    }
    if(lamaPos < 0) {
      leftToRight = true;
    }
    if((lamaPos+120) > document.documentElement.clientWidth) {
      leftToRight = false;
    }
    let lama = document.getElementById("lama");
    lama.style.transform = "translate(" + lamaPos + "px, 0px)"
    
    let lamaGif = document.getElementById("lamaGif");
    lamaGif.style.transform = leftToRight ? "scaleX(1)" : "scaleX(-1)";
  }


  //Values per selectedPoiCfg
  let selectedPoiStyle = useMemo(() => {
    return(selectedCfg ? 
      selectedStyle[selectedCfg.config.namespace + "/" + selectedCfg.config.containerId] ? 
      selectedStyle[selectedCfg.config.namespace + "/" + selectedCfg.config.containerId]
      : []
    : [])
  }, [selectedCfg, selectedStyle]);

  let selectedMapStyle = useMemo(() => {
    let mapRow = selectedCfg ? selectedCfg.config.rows.find((row) => row.type == "vectorOverlay") : null;
    if(!mapRow) return [];

    return(selectedCfg ? 
      selectedStyle[selectedCfg.config.namespace + "/" + selectedCfg.config.containerId + "//" + mapRow.id] ? 
      selectedStyle[selectedCfg.config.namespace + "/" + selectedCfg.config.containerId + "//" + mapRow.id]
      : []
    : [])
  }, [selectedCfg, selectedStyle]);

  let gisStyles = useMemo(() => {
    return(selectedCfg ? 
      gisStylesArr[selectedCfg.config.namespace + "/" + selectedCfg.config.containerId] ? 
      gisStylesArr[selectedCfg.config.namespace + "/" + selectedCfg.config.containerId]
      : []
    : [])
  }, [selectedCfg, gisStylesArr]);

  let selectedTableRows = useMemo(() => {
    return(selectedCfg ? 
      selectedRows[selectedCfg.config.namespace + "/" + selectedCfg.config.containerId] ? 
      selectedRows[selectedCfg.config.namespace + "/" + selectedCfg.config.containerId]
      : []
      : [])
    }, [selectedCfg, selectedRows]);

  let selectedTableFilters = useMemo(() => {
    return(selectedCfg ? 
      selectedFilters[selectedCfg.config.namespace + "/" + selectedCfg.config.containerId] ? 
      selectedFilters[selectedCfg.config.namespace + "/" + selectedCfg.config.containerId]
      : {items:[]}
      : {items:[]})
    }, [selectedCfg, selectedFilters]);

  let hasMapLayer = useMemo(() => {
    return(selectedCfg ? selectedCfg.config.rows.find((row) => row.type == "vectorOverlay") != null : false)
    }, [selectedCfg]);

  let selectedPoisByUniqueId = useMemo(() => (
    selectedPois && selectedCfg ? selectedPois[selectedCfg.config["namespace"] + "/" + selectedCfg.config["containerId"]] : []
  ), [selectedCfg, selectedPois, poiList, moveEntry]);

  let selectedTabCnt = 0;
  for(let cfg of activeCfgs) {
    selectedTabCnt += 1;
    selectedTabCnt += cfg.childs.length;
  }


  let maxTabWidth = (100.0 / (selectedTabCnt));


  let showGeomIndicator = useMemo(() => {
    return(serverSyncState > 0);
  }, [serverSyncState]);

  let showStyleIndicator = useMemo(() => {
    return(serverSyncState == -1);
  }, [serverSyncState]);

  let showTableIndicator = useMemo(() => {
    return(serverSyncState == -2);
  }, [serverSyncState]);

  console.log("MAINRENDER")

  const tableViewMemo = useMemo(() => 
    <TabViewTable
      height={height}
      width={getWidth}
      appView={appView}
      loading={showTableIndicator}
      companyLogo={companyLogo}
      selectedPoisByUniqueId={selectedPoisByUniqueId}
      userSettings={props.userSettings}
      username={props.username}
      password={props.password}
      configs={props.configs}
      poiList={poiList}
      mapConfig={mapConfig}
      selectedPoiConfig={selectedCfg}
      selectedRows={selectedRows}
      selectedTableRows={selectedTableRows}
      selectedTableFilters={selectedTableFilters}
      disableButtons={showGeomIndicator}
      gisStyles={gisStyles}
      selectedStyle={selectedPoiStyle}
      selectedMapStyle={selectedMapStyle}
      onTableRowsChanged={tableRowsChanged}
      onRowDoubleClick={(poi, row) => openPoiShardFromTable(poi, row)}
      onFilterModelChange={(poiUniqueId, newFilterModel) => {
        let newSelectedFilters = {...selectedFilters};
        newSelectedFilters[poiUniqueId] = newFilterModel;
        setSelectedFilters(newSelectedFilters);
      }}
      onRowSelectionModelChange={async (poiUniqueId, rows, forceUpdateChilds, childConfig) => {
        mapData.changeIgnoreZoomOnGeometryFunction(false);
        if(!editGeometry) {
          if(selectedCfg) {
            handleSelectedRows(poiUniqueId, rows);
            if(forceUpdateChilds) {
              updatePoiList(childConfig, rows);
            }
          } else {
            handleSelectedRows("", rows)
          }
        } else {
          setSnackMessage("Geometriebearbeitung zuvor beenden")
        }
      }}
      onGisStyleChanged={onGisStyleChanged}
      onMapConfigChanged={onMapConfigChanged}
      editGeometry={editGeometry}
      onEditGeometry={(active) => setEditGeometry(active)}
      moveEntry={moveEntry}
      setMoveEntry={(active) => setMoveEntry(active)}
    />
    , [
      height,
      getWidth,
      appView,
      props.username,
      props.password,
      showTableIndicator,
      companyLogo,
      selectedPoisByUniqueId,
      props.userSettings,
      props.configs,
      poiList,
      mapConfig,
      selectedCfg,
      selectedRows,
      selectedTableRows,
      showGeomIndicator,
      gisStyles,
      selectedPoiStyle,
      selectedMapStyle,
      editGeometry,
      selectedTableFilters,
      moveEntry
    ]
  );

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "calc(100svh)", overflow: "hidden" }}>
      <div style={{ display: "flex", width: "100%", height: "calc(100svh - " + height + "px)", overflow: "hidden" }}>
        {/*APP VIEW*/}
        {false ? //getWidth > 0 
          <div key={getWidth} ref={content} style={{ width: getWidth, height: "calc(100svh - " + height + "px)", backgroundColor: theme.palette.background.default, transition: "width .35s ease" }}>
            {/*POI MENU*/}
            {getPoiMenuContent()}
          </div>
          : null}
        {/*MAP*/}
        <div style={{width: "calc(100% - " + getWidth + "px)", height: "calc(100svh - " + height + "px)"}}>
          <BasicMap
            width={getWidth}
            appView={appView}
            height={height}
            cfgList={cfgList}
            mapConfig={mapConfig}
            configs={props.configs}
            poiList={poiList}
            hasMapLayer={hasMapLayer}
            selectedPois={selectedPois}
            selectedPoiConfig={selectedCfg}
            gisStyles={gisStylesArr}
            selectedStyle={selectedStyle}
            selectedPoisByUniqueId={selectedPoisByUniqueId}
            selectedBlobs={selectedBlobs}
            layerArr={layerArr}
            favLayerArr={favLayerArr}
            enabledFavLayer={enabledFavLayer}
            onNewFavorit={onNewFavorit}
            enabledBaseLayer={enabledBaseLayer}
            enabledLayers={enabledLayers}
            editGeometry={editGeometry}
            moveEntry={moveEntry}
            setMoveEntry={(active) => setMoveEntry(active)}
            onMapMoved={onMapMoved}
            onGeometryChanged={geometryChanged}
            onBaseLayerChanged={onBaseLayerChanged}
            onFavLayerChanged={onFavLayerChanged}
            onLayerChanged={onLayerChanged}
            bearerToken={props.bearerToken}
            userSettings={props.userSettings}
            username={props.username}
            password={props.password}
          />
        </div>
      </div>
      {/*WEBVIEW*/}
      {height > 0 ? 
        <div style={{width: "calc(100vw)", height: height, overflow: "hidden", zIndex: 2}}>
          {/*TABVIEW FOR LIST VIEW*/}
          <div style={{width: "calc(100vw - " + 70 + "px)", height: "40px", display: "flex", gap: "6px", backgroundColor: theme.palette.background.paper}} onDoubleClick={toggleMaximizeTable}>
            {activeCfgs.map(config => {
              var selected = selectedCfg != null && config.config != null && (config.config["namespace"] + "/" + config.config["containerId"] === selectedCfg.config["namespace"] + "/" + selectedCfg.config["containerId"]);
              if(config.config == null) return;
              return(
                <div key={config.config["namespace"] + "/" + config.config["containerId"]} style={{display: "flex", gap: "2px", overflow: "hidden"}}>
                  <TabViewParentItem
                    key={config.config["namespace"] + "/" + config.config["containerId"]}
                    config={config}
                    selected={selected}
                    selectedRows={selectedRows}
                    maxWidth={maxTabWidth}
                    tabSize={props.tabSize}
                    onPoiSelect={(innerConfig) => selectCurrentPoiConfig(innerConfig)}
                    onPoiClose={(innerConfig) => onPoiRemovedFromTabView(innerConfig)}
                  />

                  {config.childs.map(child => {
                    var childSelected = selectedCfg != null && child.config != null && (child.config["namespace"] + "/" + child.config["containerId"] === selectedCfg.config["namespace"] + "/" + selectedCfg.config["containerId"]);
                    if(child.config == null) return;
                    return(
                      <TabViewChildItem
                        key={child.config["namespace"] + "/" + child.config["containerId"]}
                        config={child}
                        selected={childSelected}
                        selectedRows={selectedRows}
                        maxWidth={maxTabWidth}
                        tabSize={props.tabSize}
                        onPoiSelect={(innerConfig) => selectCurrentPoiConfig(innerConfig)}
                        onPoiClose={(innerConfig) => onPoiRemovedFromTabView(innerConfig)}
                      />
                    )
                  })}
                </div>
              );
            })}
            <Tooltip title="Neuer Tabellen-Tab" placement="right">
              <span>
                <IconButton size="small" style={{margin: "3px"}} onClick={handleClick}><Add/></IconButton>
              </span>
            </Tooltip>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              open={open}
              onClose={() => onPoiAddedToTabView(null)}
            >
              {props.configs.sort((a, b) => a.config.containerTitle.localeCompare(b.config.containerTitle)).map((config) => (
                <MenuItem key={config.config.namespace + "/" + config.config.containerId} onClick={() => onPoiAddedToTabView(config)} style={{gap: "4px"}}>
                  <img src={"data:image/jpeg;base64," + config.config["containerChooseIconBase64"]} alt={config.config.namespace + "/" + config.config.containerId} style={{width: "20px", userSelect: "none"}}></img>
                  {getSimpleLabel(config.config["label"])}
                </MenuItem>
              ))}
            </Menu>
            <div style={{display: "flex", marginLeft: "auto", gap: "6px", marginRight: "2px"}}>
              {showGeomIndicator ? 
                <>
                  <Typography style={{alignSelf: "center", marginRight: "10px", userSelect: "none", color: theme.palette.text.primary}}>Lade Geometrien...</Typography>
                  <LinearProgress variant="determinate" value={serverSyncState * 100} style={{width: "150px", alignSelf: "center", marginRight: "20px"}}/>
                </> 
              : null}
              {showStyleIndicator ? 
                <>
                  <Typography style={{alignSelf: "center", marginRight: "10px", userSelect: "none", color: theme.palette.text.primary}}>Lade Styles...</Typography>
                  <LinearProgress variant="indeterminate" value={serverSyncState * 100} style={{width: "150px", alignSelf: "center", marginRight: "20px"}}/>
                </> 
              : null}
                <IconButton size="small" style={{height: "34px", width: "34px", marginBlock: "3px"}} disabled={!(heightState < 4)} onClick={handleExpand}><ArrowDropUpIcon/></IconButton>
                <IconButton size="small" style={{height: "34px", width: "34px", marginBlock: "3px"}} disabled={!(heightState > 0)} onClick={handleShrink}><ArrowDropDownIcon/></IconButton>
            </div>
          </div>
          {height > 40 ? 
            tableViewMemo
          : null}
        </div>
        : null
      }
      {/*<InfoCharts diagrammOpen={diagrammOpen} tableHeight={height} poiList={poiList}/>*/}

      <Dialog
        open={saveOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Änderungen speichern?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sind Sie sicher, das Sie die Änderungen speichern möchten?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{color: "grey"}} onClick={() => {}}>Abbrechen</Button>
          <Button variant="contained" onClick={() => {}} autoFocus>Speichern</Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackMessage != ""}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        message={snackMessage}
        autoHideDuration={2000}
        TransitionComponent={Slide}
        onClose={() => setSnackMessage("")}
      />

      {/*<div style={{position: "absolute", top: "16px", right: "70px", backgroundColor: "#fff", padding: "10px", whiteSpace: "pre-line", maxHeight: "50vh", maxWidth: "300px", overflowX: "hidden", overflowY: "scroll"}}>
        <p>
          {"gisStyles: " + JSON.stringify(gisStyles, null, 2) + "\n"}
        </p>
      </div>

      <div id="lama" style={{position: "absolute", bottom: height - 6, left: "70px", overflow: "hidden", userSelect: "none"}}><img id="lamaGif" height={60} src={lama}></img></div>*/}
    </div>
  );
}

export default MapView;